import React, {
  useEffect,
  useState,
  useRef,
  useReducer,
  useCallback,
} from 'react';
import { Form } from 'react-bootstrap';
import '../../../public/css/window.css';
import { parse } from 'qs';
import { useSelector, useDispatch, batch } from 'react-redux';
import { useLocation } from 'react-router';
import dayjs from 'dayjs';
import WindowHeader from '../../components/windowHeader';
import { treatmentStudyCreators } from '../../../store/reducers/treatmentStudy.reducer';
import {
  fetchTreatmentStudyTimeline as fetchTreatmentStudyTimelineService,
  postTreatmentStudyTimeline as postTreatmentStudyTimelineService,
  patchTreatmentStudyTimeline as patchTreatmentStudyTimelineService,
  deleteTreatmentStudyTimeline as deleteTreatmentStudyTimelineService,
} from '../../../services/treatmentStudyTimelineService';
import { useFetch, useMutate } from '../../../hooks/useRequest';
import 'bootstrap';
import SingleSelectDropdown from '../../components/singleSelectDropdown';
import TextInput from '../../components/textInput';
import MutateButton from '../../components/mutateButton';
import { ALERT_MESSAGES } from '../../../assets/alert';
import ElementLoading from '../../components/elementLoading';
import DatePickerInput from '../../components/datePickerInput';

const TreatmentStudyTimelineWindow = () => {
  const { search } = useLocation();
  const dispatch = useDispatch();
  const treatmentStudyInfo = useSelector(
    (state) => state.treatmentStudyReducer.treatmentStudy?.data,
  );

  const initialState = {
    selectedTreatmentStudy: null,
    registrationDate: null,
    title: null,
    content: null,
    source: null,
    sourceLink: null,
  };

  const stateReducer = (prevState, updatedProperty) => ({
    ...prevState,
    ...updatedProperty,
  });

  const [windowId, setWindowId] = useState('');
  const [state, setState] = useReducer(stateReducer, initialState);
  const [isCanceled, setIsCanceled] = useState(false);
  const didCancel = useRef(false);
  const [isLoading, setIsLoading] = useState(true);
  const [initialTreatmentStudyId, setInitialTreatmentStudyId] = useState(null);
  const [
    initialTreatmentStudyTimelineId,
    setInitialTreatmentStudyTimelineId,
  ] = useState(null);

  const {
    data: fetchTreatmentStudyTimelineData,
    call: fetchTreatmentStudyTimeline,
  } = useFetch(
    null,
    fetchTreatmentStudyTimelineService,
    initialTreatmentStudyTimelineId,
    initialTreatmentStudyId,
  );

  const { mutate: postTreatmentStudyTimeline, done: isPosted } = useMutate(
    postTreatmentStudyTimelineService,
    state.selectedTreatmentStudy?.id,
    {
      registrationDate: dayjs(state.registrationDate).format('YYYY-MM-DD'),
      title: state.title,
      content: state.content,
      source: state.source,
      sourceLink: state.sourceLink,
    },
  );

  const { mutate: patchTreatmentStudyTimeline, done: isPatched } = useMutate(
    patchTreatmentStudyTimelineService,
    initialTreatmentStudyTimelineId,
    initialTreatmentStudyId,

    {
      registrationDate: dayjs(state.registrationDate).format('YYYY-MM-DD'),
      title: state.title,
      content: state.content,
      source: state.source,
      sourceLink: state.sourceLink,
    },
  );

  const { mutate: deleteTreatmentStudyTimeline, done: isDeleted } = useMutate(
    deleteTreatmentStudyTimelineService,
    initialTreatmentStudyTimelineId,
    initialTreatmentStudyId,
  );

  const setupBeforeUnloadListener = (data) => {
    window.addEventListener('beforeunload', (event) => {
      event.preventDefault();
      return window?.opener?.postMessage(data, '/');
    });
  };

  useEffect(() => {
    const params = parse(search, {
      ignoreQueryPrefix: true,
    });
    setWindowId(params.id ? params.id : params.new);
    dispatch(treatmentStudyCreators.fetchAllTreatmentStudies.request());
    if (params.id && params.subId) {
      setInitialTreatmentStudyTimelineId(params.id);
      setInitialTreatmentStudyId(params.subId);
    } else {
      setInitialTreatmentStudyTimelineId('');
      setInitialTreatmentStudyId('');
      setIsLoading(false);
    }
    setupBeforeUnloadListener(`close ${windowId}`);
  }, [search, windowId, dispatch]);

  useEffect(() => {
    if (initialTreatmentStudyTimelineId && initialTreatmentStudyId)
      fetchTreatmentStudyTimeline();
  }, [
    initialTreatmentStudyTimelineId,
    initialTreatmentStudyId,
    fetchTreatmentStudyTimeline,
  ]);

  useEffect(() => {
    if (
      fetchTreatmentStudyTimelineData &&
      treatmentStudyInfo &&
      !didCancel.current
    ) {
      batch(() => {
        setState({
          selectedTreatmentStudy: treatmentStudyInfo.find(
            ({ id }) => id === fetchTreatmentStudyTimelineData.treatmentStudyId,
          ),
          registrationDate: new Date(
            fetchTreatmentStudyTimelineData.registrationDate,
          ),
          title: fetchTreatmentStudyTimelineData.title,
          content: fetchTreatmentStudyTimelineData.content,
          source: fetchTreatmentStudyTimelineData.source,
          sourceLink: fetchTreatmentStudyTimelineData.sourceLink,
        });
        didCancel.current = true;
        setIsLoading(false);
      });
    }
  }, [fetchTreatmentStudyTimelineData, treatmentStudyInfo]);

  useEffect(() => {
    let message = '';
    if (isPosted) {
      message = ALERT_MESSAGES.CREATE;
    }
    if (isPatched) {
      message = ALERT_MESSAGES.UPDATE;
    }
    if (isDeleted) {
      message = ALERT_MESSAGES.DELETE;
    }
    if (isPosted || isPatched || isDeleted) {
      alert(message);
      window.close();
    }
    if (isCanceled) {
      window.close();
    }
  }, [isDeleted, isPosted, isPatched, isCanceled]);

  const changeState = useCallback((e) => {
    setState({ [e.target.name]: e.target.value });
  }, []);

  useEffect(() => {
    if (state.selectedDiseaseGroup && !didCancel.current) {
      setState({ relatedTreatmentStudies: [] });
    }
  }, [state.selectedDiseaseGroup]);

  if (isLoading) return <ElementLoading type="치료제 타임라인" />;
  return (
    <>
      <WindowHeader title="치료제 타임라인" />
      <Form className="windowForm">
        <SingleSelectDropdown
          text="치료제"
          dropdownOptions={treatmentStudyInfo?.map((treatmentStudy) => ({
            id: treatmentStudy.id,
            key: treatmentStudy.id,
            text: treatmentStudy.productName,
            value: JSON.stringify(treatmentStudy),
          }))}
          attributeName="productName"
          selectedItem={state.selectedTreatmentStudy}
          setItem={changeState}
          name="selectedTreatmentStudy"
        />
        <DatePickerInput
          text="등록일"
          value={state.registrationDate}
          setDate={changeState}
          name="registrationDate"
        />
        <TextInput
          text="제목"
          value={state.title}
          onChange={changeState}
          isEssential={true}
          name="title"
        />
        <TextInput
          text="내용"
          value={state.content}
          onChange={changeState}
          as="textarea"
          rows="10"
          name="content"
        />
        <TextInput
          text="출처"
          value={state.source}
          onChange={changeState}
          name="source"
        />
        <TextInput
          text="출처 링크"
          value={state.sourceLink}
          onChange={changeState}
          name="sourceLink"
        />
        <MutateButton
          elementId={initialTreatmentStudyTimelineId}
          create={postTreatmentStudyTimeline}
          update={patchTreatmentStudyTimeline}
          remove={deleteTreatmentStudyTimeline}
          setIsCanceled={setIsCanceled}
        />
      </Form>
    </>
  );
};

export default TreatmentStudyTimelineWindow;
