import React, {
  useEffect,
  useState,
  useRef,
  useReducer,
  useCallback,
} from 'react';
import { Form, Spinner, Row, Col } from 'react-bootstrap';
import '../../../public/css/window.css';
import 'bootstrap';
import { parse } from 'qs';
import { useLocation } from 'react-router';
import { useSelector, useDispatch, batch, shallowEqual } from 'react-redux';
import dayjs from 'dayjs';
import LoadingOverlay from 'react-loading-overlay';
import WindowHeader from '../../components/windowHeader';
import { dictionaryCreators } from '../../../store/reducers/dictionary.reducer';
import { diseaseTypeCreators } from '../../../store/reducers/diseaseType.reducer';
import { treatmentStudyCreators } from '../../../store/reducers/treatmentStudy.reducer';
import { dnaCreators } from '../../../store/reducers/dna.reducer';
import { tagCreators } from '../../../store/reducers/tag.reducer';
import TextInput from '../../components/textInput';
import MultiSelectDropdown from '../../components/multiSelectDropdown';
import DatePickerInput from '../../components/datePickerInput';
import MutateButton from '../../components/mutateButton';
import ElementLoading from '../../components/elementLoading';
import CheckBoxSelect from '../../components/checkBoxSelect';
import { useEditorImageDelete } from '../../util/useEditorImage';
import {
  fetchTreatmentNews,
  postTreatmentNews,
  patchTreatmentNews,
  deleteTreatmentNews,
} from '../../../services/treatmentNewsService';
import { useFetch, useMutate } from '../../../hooks/useRequest';
import imagesOnPage from '../../../util/imagesOnPage';
import TinymceInput from '../../components/tinymceInput';
import AntTinymceInput from '../../components/antTinymceInput';
import { ALERT_MESSAGES } from '../../../assets/alert';
import { useDistinctFileUpload } from '../../util/useDistinctImage';
import DistinctImage from '../../components/distinctImage';
import { TOPIC_TAG_TYPE } from '../../../services/tagService';

const TreatmentNewsWindow = () => {
  const { search } = useLocation();
  const dispatch = useDispatch();
  const {
    dictionaryInfo,
    dnaInfo,
    patientJourneyInfo,
    targetUserInfo,
    topicInfo,
    diseaseTypesInfo,
  } = useSelector((state) => {
    return {
      dictionaryInfo: state.dictionaryReducer.dictionary.data,
      dnaInfo: state.dnaReducer.dna.data?.map((dna) => ({
        ...dna,
        krName: dna.name,
      })),
      patientJourneyInfo: state.tagReducer.tags.data?.filter(
        (tag) => tag.type === 'patientJourney',
      ),
      targetUserInfo: state.tagReducer.tags.data?.filter(
        (tag) => tag.type === 'targetUser',
      ),
      topicInfo: state.tagReducer.tags.data?.filter(
        (tag) => tag.type === TOPIC_TAG_TYPE,
      ),
      diseaseTypesInfo: state.tagReducer.tags.data?.filter(
        (tag) => tag.type === 'diseaseType',
      ),
    };
  }, shallowEqual);

  const initialState = {
    title: '',
    subtitle: '',
    photos: [],
    wroteAt: new Date(),
    content: '',
    selectedDictionaries: [],
    selectedDnaTypes: [],
    patientJourneyTypeTags: [],
    targetUserTypeTags: [],
    selectedTargetDiseaseTypeTags: [],
    topicTypeTags: [],
    selectedRelatedDiseaseTypeTags: [],
    shareLink: '',
    thumbnailFilePath: '',
    styleVersion: 1,
  };

  const stateReducer = (prevState, updatedProperty) => ({
    ...prevState,
    ...updatedProperty,
  });

  const [state, setState] = useReducer(stateReducer, initialState);
  const contentRef = useRef('');
  const [windowId, setWindowId] = useState('');
  const [initialPhotos, setInitialPhotos] = useState([]);
  const [addedPhotos, setAddedPhotos] = useState([]);
  const [distinctFileInputEvent, setDistinctFileInputEvent] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [arrayToDelete, setArrayToDelete] = useState([]);
  const [windowClose, setWindowClose] = useState(false);
  const [treatmentNewsId, setTreatmentNewsId] = useState(null);
  const [uploadUrl, setUploadUrl] = useState(null);
  const [imageLoading, setImageLoading] = useState(false);

  const didCancel = useRef(false);
  const [isCanceled, setIsCanceled] = useState(false);
  const isDeleteImageExist = useRef(null);
  const deleteDone = useRef(false);
  const fileUploadReady = useRef(false);

  const { data: fetchTreatmentNewsData, call: callTreatmentNews } = useFetch(
    null,
    fetchTreatmentNews,
    treatmentNewsId,
  );

  const postPatchData = {
    title: state.title,
    subtitle: state.subtitle,
    photos: state.photos,
    wroteAt: dayjs(state.wroteAt).format(),
    content: state.content,
    dictionaryIds:
      state.styleVersion === 1
        ? state.selectedDictionaries.map((dictionary) => dictionary.id)
        : state.selectedDictionaries
            ?.map((dictionary) => {
              return dictionaryInfo?.find((info) => info.krName === dictionary)
                ?.id;
            })
            ?.filter(Boolean),
    styleVersion: state.styleVersion,
    dnaIds: state.selectedDnaTypes.map((dna) => dna.id),
    tags: [
      ...([
        ...(state.patientJourneyTypeTags ?? []),
        ...(state.targetUserTypeTags ?? []),
        ...(state.topicTypeTags ?? []),
      ]
        ?.filter(({ selected }) => selected)
        ?.map((tag) => tag.id) ?? []),
      ...(state.selectedTargetDiseaseTypeTags.map((disease) => disease.id) ??
        []),
    ],
    relationNewsTags: [
      ...([
        ...(state.patientJourneyTypeTags ?? []),
        ...(state.targetUserTypeTags ?? []),
        ...(state.topicTypeTags ?? []),
      ]
        ?.filter(({ selected }) => selected)
        ?.map((tag) => tag.id) ?? []),
      ...(state.selectedTargetDiseaseTypeTags.map((disease) => disease.id) ??
        []),
    ],
    shareLink: state.shareLink,
    diseaseTypeIds: [],
    treatmentStudyIds: [],
    thumbnailFilePath: state.thumbnailFilePath || null,
  };

  const { mutate: putTreatmentNews, done: isPosted } = useMutate(
    postTreatmentNews,
    postPatchData,
  );

  const { mutate: changeTreatmentNews, done: isPatched } = useMutate(
    patchTreatmentNews,
    treatmentNewsId,
    postPatchData,
  );

  const { mutate: eraseTreatmentNews, done: isDeleted } = useMutate(
    deleteTreatmentNews,
    treatmentNewsId,
  );

  const {
    done: editorImageDeleteDone,
    imageDelete: editorImageDelete,
  } = useEditorImageDelete(arrayToDelete);

  const {
    publicUrl: distinctPublicUrl,
    done: distinctImageUploadDone,
    imageUpload: distinctImageUpload,
    initialize: distinctUploadInitialize,
  } = useDistinctFileUpload(distinctFileInputEvent);

  useEffect(() => {
    if (distinctImageUploadDone && distinctPublicUrl) {
      setState({ thumbnailFilePath: distinctPublicUrl });
      distinctUploadInitialize();
      setImageLoading(false);
    }
  }, [distinctImageUploadDone, distinctPublicUrl, distinctUploadInitialize]);

  const readyToDeleteDistinctImage = () => {
    setState({
      thumbnailFilePath: '',
    });
  };

  const figureFileInput = async (e) => {
    e.persist();
    if (e.target.files[0] !== undefined) {
      setDistinctFileInputEvent(e);
      fileUploadReady.current = true;
      setImageLoading(true);
    }
  };

  useEffect(() => {
    if (distinctFileInputEvent && fileUploadReady.current) {
      distinctImageUpload();
      fileUploadReady.current = false;
    }
  }, [distinctFileInputEvent, distinctImageUpload]);

  const setupBeforeUnloadListener = (data) => {
    window.addEventListener('beforeunload', (event) => {
      event.preventDefault();
      return window?.opener?.postMessage(data, '/');
    });
  };

  useEffect(() => {
    const params = parse(search, {
      ignoreQueryPrefix: true,
    });
    setWindowId(params.id ? params.id : params.new);
    dispatch(dictionaryCreators.fetchAllDictionary.request());
    dispatch(diseaseTypeCreators.fetchAllDiseaseTypes.request());
    dispatch(tagCreators.fetchAllTags.request());
    dispatch(dnaCreators.fetchAllDnas.request());
    dispatch(treatmentStudyCreators.fetchAllTreatmentStudies.request());
    if (params.id) {
      setTreatmentNewsId(params.id);
    } else {
      setTreatmentNewsId('');
      setIsLoading(false);
    }
    setupBeforeUnloadListener(`close ${windowId}`);
  }, [search, windowId, dispatch]);

  useEffect(() => {
    if (treatmentNewsId) callTreatmentNews();
    else {
      const saved = localStorage.getItem('content');
      if (saved) {
        setState({ content: saved });
        contentRef.current = saved;
      }
      const id = setInterval(
        () => localStorage.setItem('content', contentRef.current),
        15000,
      );
      return () => clearInterval(id);
    }
  }, [treatmentNewsId, callTreatmentNews]);

  useEffect(() => {
    if (
      fetchTreatmentNewsData &&
      patientJourneyInfo &&
      targetUserInfo &&
      topicInfo &&
      !didCancel.current
    ) {
      setInitialPhotos(fetchTreatmentNewsData.photos.map(({ uri }) => uri));
      setState({
        title: fetchTreatmentNewsData.title,
        subtitle: fetchTreatmentNewsData.subtitle,
        photos: fetchTreatmentNewsData.photos,
        wroteAt: new Date(fetchTreatmentNewsData.wroteAt),
        content: fetchTreatmentNewsData.content,
        selectedDictionaries:
          fetchTreatmentNewsData.styleVersion === 1
            ? fetchTreatmentNewsData.dictionaries
            : fetchTreatmentNewsData.dictionaries?.map((dictionary) => {
                return dictionary?.krName;
              }),
        styleVersion: fetchTreatmentNewsData.styleVersion,
        selectedDnaTypes: fetchTreatmentNewsData.dnas,
        selectedTargetDiseaseTypeTags: fetchTreatmentNewsData.tags?.filter(
          (tag) => tag.type === 'diseaseType',
        ),
        patientJourneyTypeTags: patientJourneyInfo?.map((tag) => ({
          ...tag,
          selected: JSON.stringify(fetchTreatmentNewsData.tags)?.includes(
            tag.id,
          ),
        })),
        targetUserTypeTags: targetUserInfo?.map((tag) => ({
          ...tag,
          selected: JSON.stringify(fetchTreatmentNewsData.tags)?.includes(
            tag.id,
          ),
        })),
        topicTypeTags: topicInfo?.map((tag) => ({
          ...tag,
          selected: JSON.stringify(fetchTreatmentNewsData.tags)?.includes(
            tag.id,
          ),
        })),
        shareLink: fetchTreatmentNewsData.shareLink ?? '',
        thumbnailFilePath: fetchTreatmentNewsData?.thumbnailFilePath,
      });
      didCancel.current = true;
      setIsLoading(false);
    }
    if (
      treatmentNewsId === '' &&
      !didCancel.current &&
      patientJourneyInfo &&
      targetUserInfo &&
      topicInfo
    ) {
      setState({
        patientJourneyTypeTags: patientJourneyInfo?.map((tag) => ({
          ...tag,
          selected: false,
        })),
        targetUserTypeTags: targetUserInfo?.map((tag) => ({
          ...tag,
          selected: false,
        })),
        topicTypeTags: topicInfo?.map((tag) => ({
          ...tag,
          selected: false,
        })),
      });
      didCancel.current = true;
      setIsLoading(false);
    }
  }, [
    treatmentNewsId,
    fetchTreatmentNewsData,
    dictionaryInfo,
    dnaInfo,
    patientJourneyInfo,
    targetUserInfo,
    topicInfo,
  ]);

  useEffect(() => {
    if (arrayToDelete.length > 0 && !deleteDone.current) {
      editorImageDelete();
      setWindowClose(true);
      deleteDone.current = true;
    }
  }, [arrayToDelete, editorImageDelete, deleteDone]);

  useEffect(() => {
    let message = '';
    if (isPosted) {
      message = ALERT_MESSAGES.CREATE;
      localStorage.removeItem('content');
    }
    if (isPatched) {
      message = ALERT_MESSAGES.UPDATE;
    }
    if (isDeleted) {
      message = ALERT_MESSAGES.DELETE;
    }
    if (isDeleteImageExist.current) {
      if (arrayToDelete.length === 0 && windowClose) {
        if (isPosted || isPatched || isDeleted) {
          setWindowClose(false);
          alert(message);
          window.close();
        }
        if (isCanceled) {
          setWindowClose(false);
          window.close();
        }
      } else if (
        arrayToDelete.length > 0 &&
        windowClose &&
        editorImageDeleteDone
      ) {
        if (isPosted || isPatched || isDeleted) {
          setWindowClose(false);
          alert(message);
          window.close();
          setArrayToDelete([]);
        }
        if (isCanceled) {
          setWindowClose(false);
          setArrayToDelete([]);
          window.close();
        }
      }
    } else {
      if (isPosted || isPatched || isDeleted) {
        setWindowClose(false);
        alert(message);
        window.close();
      }
      if (isCanceled) {
        setWindowClose(false);
        window.close();
      }
    }
  }, [
    windowClose,
    arrayToDelete,
    isDeleted,
    isPosted,
    isPatched,
    isCanceled,
    editorImageDeleteDone,
  ]);

  const renewalImages = (document) => {
    const images = imagesOnPage(document);
    setState({ photos: images.map((image) => ({ uri: image })) });
  };

  useEffect(() => {
    if (uploadUrl && !state.photos.map(({ uri }) => uri).includes(uploadUrl)) {
      const newPhotos = [...state.photos, { uri: uploadUrl }];
      batch(() => {
        setState({ photos: newPhotos });
        setAddedPhotos((prev) => [...prev, uploadUrl]);
        setUploadUrl(null);
      });
    }
  }, [uploadUrl, state.photos]);

  const changeState = useCallback((e) => {
    setState({ [e.target.name]: e.target.value });
  }, []);

  if (isLoading) return <ElementLoading type="소식" />;
  return (
    <LoadingOverlay
      active={imageLoading}
      spinner={<Spinner animation="border" variant="info" />}
      text={<p>이미지를 업로드 하는 중</p>}
    >
      <WindowHeader title="소식" />
      <Form className="windowForm treatmentWindow">
        <TextInput
          text="제목"
          value={state.title}
          onChange={changeState}
          isEssential={true}
          name="title"
        />
        <TextInput
          text="부제목"
          value={state.subtitle}
          onChange={changeState}
          isEssential={true}
          name="subtitle"
        />
        <DatePickerInput
          text="등록일"
          value={state.wroteAt}
          setDate={changeState}
          isEssential={true}
          name="wroteAt"
        />
        <DistinctImage
          text="썸네일 이미지"
          imageFileInput={(e) => figureFileInput(e)}
          images={state.thumbnailFilePath}
          deleteImage={readyToDeleteDistinctImage}
        />
        <Form.Group className="formContent treatmentCheck" as={Row}>
          <Form.Label column sm="3" md="2" xl="1">
            스타일 버전
          </Form.Label>
          <Col sm="9" md="10" xl="11">
            <Form.Group className="formContent checkBoxContainer" as={Row}>
              <Form.Check
                inline
                label="1"
                name="styleVersion"
                type="radio"
                id="radio-1"
                onChange={() => {
                  setState({
                    styleVersion: 1,
                    selectedDictionaries: state.selectedDictionaries
                      ?.map((dictionary) => {
                        return dictionaryInfo?.find(
                          (info) => info.krName === dictionary,
                        );
                      })
                      ?.filter(Boolean),
                  });
                }}
                checked={state.styleVersion === 1}
              />
              <Form.Check
                inline
                label="2"
                name="styleVersion"
                type="radio"
                id="radio-2"
                onChange={() => {
                  setState({
                    styleVersion: 2,
                    selectedDictionaries: state.selectedDictionaries?.map(
                      (dictionary) => {
                        return dictionary?.krName;
                      },
                    ),
                  });
                }}
                checked={state.styleVersion === 2}
              />
            </Form.Group>
          </Col>
        </Form.Group>

        {state.styleVersion === 1 && (
          <>
            <TinymceInput
              content={state.content}
              setContent={
                treatmentNewsId
                  ? changeState
                  : (e) => {
                      changeState(e);
                      contentRef.current = e.target.value;
                    }
              }
              windowId={treatmentNewsId}
              setUploadUrl={setUploadUrl}
              setImageLoading={setImageLoading}
              contentLinkData={{
                dictionaryInfo,
                diseaseTypeInfo: [],
                dnaInfo: [],
                setState,
              }}
              name="content"
            />
            <MultiSelectDropdown
              text="본문 링크"
              data={[
                {
                  option: dictionaryInfo,
                  selectedOption: state.selectedDictionaries,
                  setOption: changeState,
                  optionName: '용어',
                  name: 'selectedDictionaries',
                },
                {
                  option: dnaInfo,
                  selectedOption: state.selectedDnaTypes,
                  setOption: changeState,
                  optionName: '유전자',
                  name: 'selectedDnaTypes',
                },
              ]}
            />
          </>
        )}
        {state.styleVersion === 2 && (
          <>
            <Form.Group className="formContent" as={Row}>
              <Form.Label column sm="3" md="2" xl="1">
                내용 <span className="required-tag">*필수</span>
              </Form.Label>
              <Col sm="9" md="10" xl="11">
                <AntTinymceInput
                  content={state.content}
                  setContent={(e) => {
                    setState({ content: e });
                  }}
                  setImageLoading={setImageLoading}
                  onBlur={(e) => {}}
                  contentLinkData={{ dictionaryInfo, setState }}
                />
              </Col>
            </Form.Group>
            <TextInput
              text="본문 링크"
              value={state.selectedDictionaries}
              placeholder="용어 본문 링크입니다."
              name="selectedDictionaries"
            />
          </>
        )}
        <hr />
        <CheckBoxSelect
          text="태그 주기"
          checkBoxList={state.patientJourneyTypeTags}
          setCheckBoxList={changeState}
          isEssential={false}
          name="patientJourneyTypeTags"
        />
        <CheckBoxSelect
          text="태그 유형"
          checkBoxList={state.targetUserTypeTags}
          setCheckBoxList={changeState}
          isEssential={false}
          name="targetUserTypeTags"
        />
        <CheckBoxSelect
          text="태그 치료기술"
          checkBoxList={state.topicTypeTags}
          setCheckBoxList={changeState}
          isEssential={false}
          name="topicTypeTags"
        />
        <MultiSelectDropdown
          text="태그 질환"
          data={[
            {
              option: diseaseTypesInfo,
              selectedOption: state.selectedTargetDiseaseTypeTags,
              setOption: changeState,
              optionName: '태그 질환(복수 선택 가능)을 선택하세요',
              name: 'selectedTargetDiseaseTypeTags',
            },
          ]}
        />
        <hr />
        <TextInput
          text="공유 링크"
          value={state.shareLink}
          onChange={changeState}
          name="shareLink"
          placeholder="미입력 시 자동으로 생성됩니다"
        />
        <MutateButton
          content={state.content}
          elementId={treatmentNewsId}
          create={putTreatmentNews}
          update={changeTreatmentNews}
          remove={eraseTreatmentNews}
          initialPhotos={initialPhotos}
          addedPhotos={addedPhotos}
          setEditorRemoveArray={setArrayToDelete}
          isDeleteImageExist={isDeleteImageExist}
          setIsCanceled={setIsCanceled}
        />
      </Form>
    </LoadingOverlay>
  );
};
export default TreatmentNewsWindow;
