import { Button, DatePicker, Layout, Switch } from "antd";
import moment from "moment";
import { parse } from "qs";
import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom/cjs/react-router-dom";
import { Header, SideBar, TitleBreadcrumb } from '../../../component';
import { useFetch } from '../../../hooks/useRequest';
import useWindow from '../../../hooks/useWindow';
import {
  fetchMediaMonitorConfigById,
  fetchMediaMonitorConfigNews,
  reloadMediaMonitorConfig,
  updateMediaMonitorConfigNews,
} from '../../../services/mediaMonitorService';
import { diseaseTypeCreators } from '../../../store/reducers/diseaseType.reducer';
import AntList from '../../components/antList';
import { useAppSync } from '../../../hooks/useAppSync';

const MediaMonitorNews = () => {
  const [pageUpdate, setPageUpdate] = useState(1);
  const { search } = useLocation();
  const [windowId, setWindowId] = useState('');
  const [configId, setConfigId] = useState(null);
  const [loading, setLoading] = useState(false);
  const [loadingText, setLoadingText] = useState('');
  const [startDate, setStartDate] = useState(
    moment().subtract(1, 'days').startOf('day'),
  );
  const [endDate, setEndDate] = useState(
    moment().subtract(1, 'days').endOf('day'),
  );
  const { findWindowById, createWindow, destroyWindowById } = useWindow();
  const { subscribe, unsubscribe } = useAppSync();

  const dispatch = useDispatch();
  const { diseaseTypeData } = useSelector((state) => {
    const diseaseTypes = state.diseaseTypeReducer.diseaseTypes?.data;
    return {
      diseaseTypeData: diseaseTypes,
    };
  });
  const getData = useCallback(() => {
    dispatch(diseaseTypeCreators.fetchAllDiseaseTypes.request());
  }, [dispatch]);

  const { data: config, call: getConfig } = useFetch(
    null,
    fetchMediaMonitorConfigById,
    configId,
  );

  const {
    data: { data: newsList },
    call: getNewsList,
  } = useFetch([], fetchMediaMonitorConfigNews, configId, startDate, endDate);

  useEffect(() => {
    getData();
    if (configId) {
      getConfig();
      getNewsList();
    }
  }, [getConfig, getData, configId, getNewsList]);

  useEffect(() => {
    if (configId) {
      getNewsList();
    }
  }, [configId, startDate, endDate, getNewsList]);

  useEffect(() => {
    const params = parse(search, {
      ignoreQueryPrefix: true,
    });

    setWindowId(params.id ? params.id : params.new);
    if (params.id) {
      setConfigId(params.id);
    }
  }, [search, windowId]);

  // receive messages from NoticeWindow
  const receiveMessage = useCallback(
    (event) => {
      if (
        event.origin !== window.location.origin ||
        typeof event.data !== 'string'
      )
        return;
      const [command, id] = event.data.split(' ');
      if (command === 'close') {
        destroyWindowById(id);
      }
      getNewsList();
    },
    [getNewsList, destroyWindowById],
  );

  // add event listner(to receive message) & fetch data at mount
  useEffect(() => {
    window.addEventListener('message', receiveMessage, false);
    return () => {
      window.removeEventListener('message', receiveMessage, false);
    };
  }, [receiveMessage]);

  const handleCreateEditWindow = useCallback(
    (newsId) => {
      if (findWindowById(newsId)) {
        alert('이미 편집중인 설정입니다.');
        return;
      }
      createWindow({
        id: newsId,
        configId: config.id,
        dataType: 'mediaMonitorNews',
      });
    },
    [config],
  );

  const handleCreateLetterWindow = useCallback(() => {
    createWindow({
      id: configId,
      dataType: 'mediaMonitorLetter',
    });
  }, [configId, createWindow]);

  const reloadNewsList = useCallback(async () => {
    if (config) {
      if (config.status === 'LOADING') {
        alert('적재중입니다!');
        return;
      }
      try {
        setLoading(true);
        const requestId = await reloadMediaMonitorConfig(config.id);

        subscribe(
          requestId,
          ({ status, message }) => {
            if (status === 'LOADING') {
              setLoadingText(`적재 중... ${message}%`);
            }
            if (status === 'FILTERING') {
              setLoadingText(`필터링 중... ${message}%`);
            }
            if (status === 'PROCESSING') {
              setLoadingText(`후 처리 중... ${message}%`);
            }
            if (status === 'CLUSTERING') {
              setLoadingText(`유사도 측정 중... ${message}%`);
            }
            if (status === 'COMPLETE') {
              setLoading(false);
              setLoadingText('');
              alert('적재가 완료되었습니다.');
              unsubscribe();
              setStartDate(moment().subtract(1, 'days').startOf('day'));
              setEndDate(moment().subtract(1, 'days').endOf('day'));
              getNewsList();
            }
          },
          (e) => {
            setLoadingText('');
            alert('적재 중 오류가 발생했습니다.');
            unsubscribe();
            window.location.reload();
          },
        );

        getConfig();
      } catch (e) {
        setLoading(false);
        setLoadingText('');
        alert('적재 중 오류가 발생했습니다.');
        window.location.reload();
      }
    }
  }, [config, getConfig]);

  const updateNewsEligible = useCallback(
    (newsIs, eligible) => {
      updateMediaMonitorConfigNews(configId, newsIs, { eligible })
        .then(() => {
          getNewsList();
        })
        .catch((e) => {
          alert('적합성 업데이트 중 오류가 발생했습니다.');
        });
    },
    [configId, getNewsList],
  );

  const columns = [
    {
      title: '데이터 확인 / 수정',
      dataIndex: 'id',
      key: 'id',
      render: (id) => (
        <Button onClick={() => handleCreateEditWindow(id)}>
          데이터 확인 / 수정 {'>'}
        </Button>
      ),
      width: 120,
    },
    {
      title: '국내 / 해외',
      dataIndex: 'lang',
      key: 'lang',
      render: (lang) => (lang === 'ko' ? '국내' : '해외'),
      filters: [
        { text: '국내', value: 'ko' },
        { text: '해외', value: 'en' },
      ],
      onFilter: (value, record) => record.lang === value,
      width: 100,
    },
    {
      title: '기사 발행일',
      dataIndex: 'publishedDate',
      key: 'publishedDate',
      sorter: (a, b) =>
        moment(a.publishedDate)?.compareTo(moment(b.publishedDate)),
      render: (text) => (text ? moment(text).format('YY.MM.DD') : '-'),
      width: 100,
    },
    {
      title: '기사 제목',
      dataIndex: 'title',
      key: 'title',
      sorter: (a, b) => a.title?.localeCompare(b.title),
      render: (text, record) => (
        <div
          style={{
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
            maxWidth: '300px',
          }}
        >
          <a href={record.url} target="_blank" rel="noreferrer">
            {text}
          </a>
        </div>
      ),
    },
    {
      title: '대상 질환',
      dataIndex: 'diseaseTypeId',
      key: 'diseaseTypeId',
      filters: config?.diseaseTypeIds?.map((id) => ({
        text: diseaseTypeData?.find((data) => data.id === id)?.krName ?? id,
        value: id,
      })),
      onFilter: (value, record) => record.diseaseTypeId === value,
      render: (value) =>
        diseaseTypeData?.find((data) => data.id === value)?.krName ?? value,
      width: 100,
    },
    {
      title: '발행 미디어',
      dataIndex: 'siteName',
      key: 'siteName',
      sorter: (a, b) => a.siteName?.localeCompare(b.siteName),
      width: 100,
    },
    {
      title: '작성자',
      dataIndex: 'author',
      key: 'author',
      sorter: (a, b) => a.author?.localeCompare(b.author),
      width: 100,
    },
    {
      title: '적합성',
      dataIndex: 'eligible',
      key: 'eligible',
      filters: [
        { text: '적합', value: true },
        { text: '부적합', value: false },
      ],
      onFilter: (value, record) => record.eligible === value,
      render: (value, record) => (
        <Switch
          checked={value}
          onChange={(e) => updateNewsEligible(record.id, !record.eligible)}
        />
      ),
      width: 80,
    },
    {
      title: '유사도 그룹',
      dataIndex: 'groupId',
      key: 'groupId',
      sorter: (a, b) => a?.groupId?.localeCompare(b?.groupId),
      width: 80,
    },
    {
      title: '설명 / GPT 요약문',
      dataIndex: 'description',
      key: 'description',
      sorter: (a, b) => a.description?.localeCompare(b.description),
      render: (text) => (
        <div
          style={{
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
            maxWidth: '400px',
          }}
        >
          {text}
        </div>
      ),
    },
  ];

  return (
    <Layout>
      <Header className="site-layout-background" />
      <Layout className="site-layout contentLayout">
        <SideBar tab="tabContent" link="mediaMonitorConfig" />
        <Layout className="right-layout">
          <TitleBreadcrumb
            title="콘텐츠 / 미디어 모니터링"
            subTitle="검수"
            className="white-bg"
          />
          <Layout.Content className="site-layout-background contentStyle">
            <Button
              type="primary"
              disabled={config?.status === 'LOADING'}
              onClick={reloadNewsList}
            >
              재적재
            </Button>
            <Button
              type="primary"
              style={{ marginLeft: 10, marginBottom: 16 }}
              onClick={handleCreateLetterWindow}
            >
              뉴스레터 미리보기
            </Button>
            <DatePicker.RangePicker
              id="loadedDate"
              style={{ float: 'right' }}
              defaultValue={[startDate, endDate]}
              onChange={(dates) => {
                setStartDate(moment(dates[0]).startOf('day'));
                setEndDate(moment(dates[1]).endOf('day'));
              }}
              format="YYYY-MM-DD"
            />
            <AntList
              loading={
                loading ? { spinning: loading, tip: loadingText } : undefined
              }
              columns={columns}
              dataSource={newsList}
              pagination={{
                pageSize: 20,
                showSizeChanger: true,
                current: pageUpdate,
                onChange: (page) => setPageUpdate(page),
              }}
            />
          </Layout.Content>
        </Layout>
      </Layout>
    </Layout>
  );
};

export default MediaMonitorNews;
