import React, {
  useEffect,
  useState,
  useRef,
  useReducer,
  useCallback,
  useMemo,
} from 'react';
import { Form, Spinner } from 'react-bootstrap';
import '../../../public/css/window.css';
import 'bootstrap';
import { parse } from 'qs';
import { useLocation } from 'react-router';
import { useSelector, useDispatch, batch, shallowEqual } from 'react-redux';
import LoadingOverlay from 'react-loading-overlay';
import WindowHeader from '../../components/windowHeader';
import { treatmentTypeCreators } from '../../../store/reducers/treatmentType.reducer';
import { diseaseTypeCreators } from '../../../store/reducers/diseaseType.reducer';
import { dictionaryCreators } from '../../../store/reducers/dictionary.reducer';
import { dnaCreators } from '../../../store/reducers/dna.reducer';
import TextInput from '../../components/textInput';
import MultiSelectDropdown from '../../components/multiSelectDropdown';
import MutateButton from '../../components/mutateButton';
import ElementLoading from '../../components/elementLoading';
import { useEditorImageDelete } from '../../util/useEditorImage';
import {
  fetchTreatmentStudy,
  postTreatmentStudy,
  patchTreatmentStudy,
  deleteTreatmentStudy,
} from '../../../services/treatmentStudyService';
import { useFetch, useMutate } from '../../../hooks/useRequest';
import SingleSelectDropdown from '../../components/singleSelectDropdown';
import imagesOnPage from '../../../util/imagesOnPage';
import TinymceInput from '../../components/tinymceInput';
import { ALERT_MESSAGES } from '../../../assets/alert';
import CheckBoxSelect from '../../components/checkBoxSelect';

const TreatmentStudyWindow = () => {
  const { search } = useLocation();
  const dispatch = useDispatch();
  const {
    diseaseGroups,
    treatmentTypes,
    diseaseTypes,
    dictionaryInfo,
    dnaInfo,
  } = useSelector((state) => {
    return {
      diseaseGroups: state.diseaseGroupReducer.diseaseGroups.data,
      treatmentTypes: state.treatmentTypeReducer.treatmentType.data,
      diseaseTypes: state.diseaseTypeReducer.diseaseTypes.data,
      dictionaryInfo: state.dictionaryReducer.dictionary.data,
      dnaInfo: state.dnaReducer.dna.data,
    };
  }, shallowEqual);

  const initialSaleCountries = [
    { id: 'kr', name: '국내', enName: 'kr', selected: false },
    { id: 'oversea', name: '국외', enName: 'oversea', selected: false },
  ];

  const initialState = {
    selectedDiseaseGroup: null,
    productName: '',
    researchInstitution: '',
    photos: [],
    carrier: '',
    stemCellSource: '',
    pharmaceuticalCompanyLink: '',
    selectedTreatmentType: null,
    selectedTargetDiseaseTypes: [],
    developmentStep: null,
    processStatus: null,
    content: '',
    selectedDictionaries: [],
    selectedRelatedDnas: [],
    selectedSaleCountries: initialSaleCountries,
  };

  const stateReducer = (prevState, updatedProperty) => ({
    ...prevState,
    ...updatedProperty,
  });

  const [state, setState] = useReducer(stateReducer, initialState);
  const [windowId, setWindowId] = useState('');
  const [initialPhotos, setInitialPhotos] = useState([]);
  const [addedPhotos, setAddedPhotos] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [arrayToDelete, setArrayToDelete] = useState([]);
  const [windowClose, setWindowClose] = useState(false);
  const [treatmentStudyId, setTreatmentStudyId] = useState(null);
  const [uploadUrl, setUploadUrl] = useState(null);
  const [imageLoading, setImageLoading] = useState(false);
  const processStatusEnumTypes = ['개발 중', '개발 중지', '시판'];
  const developmentStepEnumTypes = [
    '전임상',
    '1상',
    '1/2상',
    '2상',
    '2/3상',
    '3상',
  ];
  const processStatusOptions = processStatusEnumTypes.map((enumType) => ({
    key: enumType,
    text: enumType,
    value: JSON.stringify({ krName: enumType }),
  }));

  const developmentStepOptions = developmentStepEnumTypes.map((enumType) => ({
    key: enumType,
    text: enumType,
    value: JSON.stringify({ krName: enumType }),
  }));

  const didCancel = useRef(false);
  const [isCanceled, setIsCanceled] = useState(false);
  const isDeleteImageExist = useRef(null);
  const deleteDone = useRef(false);

  const { data: fetchTreatmentStudyData, call: callTreatmentStudy } = useFetch(
    null,
    fetchTreatmentStudy,
    treatmentStudyId,
  );

  const isProcessStatusDeveloping = useMemo(
    () => state.processStatus?.krName === '개발 중',
    [state.processStatus],
  );
  const isProcessStatusMarket = useMemo(
    () => state.processStatus?.krName === '시판',
    [state.processStatus],
  );

  const salesAt = useCallback(
    (area) => {
      return state.selectedSaleCountries.find(({ enName }) => enName === area)
        .selected;
    },
    [state.selectedSaleCountries],
  );

  const postPatchData = {
    researchInstitution: state.researchInstitution,
    pharmaceuticalCompanyLink: state.pharmaceuticalCompanyLink,
    productName: state.productName,
    treatmentTypeId: state.selectedTreatmentType?.id,
    carrier: state.carrier,
    stemCellSource: state.stemCellSource,
    diseaseTypeIds: state.selectedTargetDiseaseTypes?.map(({ id }) => id),
    developmentStep:
      state.processStatus?.krName === '시판'
        ? '시판'
        : state.processStatus?.krName === '개발 중지'
        ? '개발 중지'
        : state.developmentStep?.krName,
    processStatus: state.processStatus?.krName,
    content: state.content,
    dictionaryIds: state.selectedDictionaries?.map(({ id }) => id),
    photos: state.photos,
    diseaseGroupId: state.selectedDiseaseGroup?.id,
    dnaIds: state.selectedRelatedDnas?.map(({ id }) => id),
    saleCountry: salesAt('kr')
      ? salesAt('oversea')
        ? 'global'
        : 'kr'
      : salesAt('oversea')
      ? 'oversea'
      : null,
  };
  const { mutate: putTreatmentStudy, done: isPosted } = useMutate(
    postTreatmentStudy,
    state.selectedDiseaseGroup?.id,
    postPatchData,
  );

  const { mutate: changeTreatementStudy, done: isPatched } = useMutate(
    patchTreatmentStudy,
    treatmentStudyId,
    postPatchData,
  );

  const { mutate: eraseTreatmentStudy, done: isDeleted } = useMutate(
    deleteTreatmentStudy,
    treatmentStudyId,
  );

  const {
    done: editorImageDeleteDone,
    imageDelete: editorImageDelete,
  } = useEditorImageDelete(arrayToDelete);

  const setupBeforeUnloadListener = (data) => {
    window.addEventListener('beforeunload', (event) => {
      event.preventDefault();
      return window?.opener?.postMessage(data, '/');
    });
  };

  const message = isPosted
    ? ALERT_MESSAGES.CREATE
    : isPatched
    ? ALERT_MESSAGES.UPDATE
    : isDeleted
    ? ALERT_MESSAGES.DELETE
    : '';

  const isMutated = isPosted || isPatched || isDeleted;

  const needToDeleteEditorImage = Boolean(arrayToDelete.length > 0);

  const editorImageDeleteOnWindowCloseCondition =
    needToDeleteEditorImage && editorImageDeleteDone;

  const windowCloseCondition =
    windowClose && editorImageDeleteOnWindowCloseCondition;

  useEffect(() => {
    const params = parse(search, {
      ignoreQueryPrefix: true,
    });
    setWindowId(params.id ? params.id : params.new);
    dispatch(treatmentTypeCreators.fetchAllTreatmentType.request());
    dispatch(dictionaryCreators.fetchAllDictionary.request());
    dispatch(dnaCreators.fetchAllDnas.request());
    if (params.id) {
      setTreatmentStudyId(params.id);
    } else {
      setTreatmentStudyId('');
      setIsLoading(false);
    }
    setupBeforeUnloadListener(`close ${windowId}`);
  }, [search, windowId, dispatch]);
  useEffect(() => {
    if (state.selectedDiseaseGroup) {
      dispatch(
        diseaseTypeCreators.fetchDiseaseGroupDiseaseType.request(
          state.selectedDiseaseGroup.id,
        ),
      );
    }
  }, [dispatch, state.selectedDiseaseGroup]);

  useEffect(() => {
    if (treatmentStudyId) {
      callTreatmentStudy();
    }
  }, [treatmentStudyId, callTreatmentStudy]);

  useEffect(() => {
    if (
      fetchTreatmentStudyData &&
      diseaseGroups &&
      dictionaryInfo &&
      !didCancel.current
    ) {
      setInitialPhotos(fetchTreatmentStudyData.photos.map(({ uri }) => uri));
      setState({
        selectedDiseaseGroup: diseaseGroups?.find(
          (diseaseGroup) =>
            diseaseGroup.id === fetchTreatmentStudyData.diseaseGroupId,
        ),
        productName: fetchTreatmentStudyData.productName,
        researchInstitution: fetchTreatmentStudyData.researchInstitution,
        pharmaceuticalCompanyLink:
          fetchTreatmentStudyData.pharmaceuticalCompanyLink,
        selectedTreatmentType: fetchTreatmentStudyData.treatmentType,
        selectedTargetDiseaseTypes: fetchTreatmentStudyData.diseaseTypes,
        developmentStep: { krName: fetchTreatmentStudyData.developmentStep },
        processStatus: { krName: fetchTreatmentStudyData.processStatus },
        content: fetchTreatmentStudyData.content,
        selectedDictionaries: dictionaryInfo.filter((dictionary) =>
          fetchTreatmentStudyData.dictionaries.find(
            ({ krName }) => krName === dictionary.krName,
          ),
        ),
        selectedRelatedDnas: fetchTreatmentStudyData.dnas,
        carrier: fetchTreatmentStudyData.carrier,
        stemCellSource: fetchTreatmentStudyData.stemCellSource,
        selectedSaleCountries:
          fetchTreatmentStudyData.saleCountry === 'global'
            ? initialSaleCountries.map((initialCountry) => ({
                ...initialCountry,
                selected: true,
              }))
            : initialSaleCountries.map((initialCountry) =>
                initialCountry.enName === fetchTreatmentStudyData.saleCountry
                  ? { ...initialCountry, selected: true }
                  : initialCountry,
              ),
      });
      didCancel.current = true;
      setIsLoading(false);
    }
  }, [
    fetchTreatmentStudyData,
    diseaseGroups,
    dictionaryInfo,
    initialSaleCountries,
  ]);

  const closeWindowAfterAlert = useCallback(() => {
    if (isMutated || isCanceled) {
      setWindowClose(false);
      setArrayToDelete([]);
      if (isMutated) alert(message);
      window.close();
    }
  }, [isMutated, isCanceled, message]);

  useEffect(() => {
    if (!isDeleteImageExist.current || windowCloseCondition) {
      closeWindowAfterAlert();
    }
  }, [windowCloseCondition, closeWindowAfterAlert]);

  const renewalImages = (document) => {
    const images = imagesOnPage(document);
    setState({ photos: images.map((image) => ({ uri: image })) });
  };

  useEffect(() => {
    if (uploadUrl && !state.photos.map(({ uri }) => uri).includes(uploadUrl)) {
      const newPhotos = [...state.photos, { uri: uploadUrl }];
      batch(() => {
        setState({ photos: newPhotos });
        setAddedPhotos((prev) => [...prev, uploadUrl]);
        setUploadUrl(null);
      });
    }
  }, [uploadUrl, state.photos]);

  useEffect(() => {
    if (arrayToDelete.length > 0 && !deleteDone.current) {
      editorImageDelete();
      setWindowClose(true);
      deleteDone.current = true;
    }
  }, [arrayToDelete, editorImageDelete]);

  const changeState = useCallback((e) => {
    setState({ [e.target.name]: e.target.value });
  }, []);

  useEffect(() => {
    if (state.selectedDiseaseGroup && !didCancel.current) {
      setState({ selectedRelatedDnas: [] });
    }
  }, [state.selectedDiseaseGroup]);

  useEffect(() => {
    if (state.selectedTreatmentType && !didCancel.current) {
      setState({
        selectedTargetDiseaseTypes: [],
        carrier: '',
        stemCellSource: '',
      });
    }
  }, [state.selectedTreatmentType]);

  if (isLoading) return <ElementLoading type="치료제" />;
  return (
    <LoadingOverlay
      active={imageLoading}
      spinner={<Spinner animation="border" variant="info" />}
      text={<p>이미지를 업로드 하는 중</p>}
    >
      <WindowHeader title="치료제" />
      <Form className="windowForm">
        <SingleSelectDropdown
          text="질환 그룹"
          dropdownOptions={diseaseGroups?.map((diseaseGroup) => ({
            id: diseaseGroup.id,
            key: diseaseGroup.id,
            text: diseaseGroup.krName,
            value: JSON.stringify(diseaseGroup),
          }))}
          attributeName="krName"
          selectedItem={state.selectedDiseaseGroup}
          setItem={changeState}
          name="selectedDiseaseGroup"
        />
        <TextInput
          text="이름"
          value={state.productName}
          onChange={changeState}
          isEssential={true}
          name="productName"
        />
        <TextInput
          text="연구 책임 기관"
          value={state.researchInstitution}
          onChange={changeState}
          isEssential={true}
          name="researchInstitution"
        />
        <TextInput
          text="제약사 링크"
          value={state.pharmaceuticalCompanyLink}
          onChange={changeState}
          name="pharmaceuticalCompanyLink"
        />
        <SingleSelectDropdown
          text="치료 기술"
          dropdownOptions={treatmentTypes?.map((treatmentType) => ({
            id: treatmentType.id,
            key: treatmentType.id,
            text: treatmentType.krName,
            value: JSON.stringify(treatmentType),
          }))}
          attributeName="krName"
          selectedItem={state.selectedTreatmentType}
          setItem={changeState}
          name="selectedTreatmentType"
        />
        {state.selectedTreatmentType?.krName === '줄기세포 치료' && (
          <TextInput
            text="스템셀 소스"
            value={state.stemCellSource}
            onChange={changeState}
            name="stemCellSource"
          />
        )}
        {state.selectedTreatmentType?.krName === '유전자 치료' && (
          <>
            <MultiSelectDropdown
              text="관련 유전자"
              data={[
                {
                  option: dnaInfo,
                  selectedOption: state.selectedRelatedDnas,
                  setOption: changeState,
                  optionName: '관련 유전자(복수 선택 가능)를 선택하세요',
                  name: 'selectedRelatedDnas',
                },
              ]}
              isEssential={true}
            />
            <TextInput
              text="운반체"
              value={state.carrier}
              onChange={changeState}
              name="carrier"
            />
          </>
        )}
        <MultiSelectDropdown
          text="대상 질환"
          data={[
            {
              option: diseaseTypes,
              selectedOption: state.selectedTargetDiseaseTypes,
              setOption: changeState,
              optionName: '대상 질환(복수 선택 가능)을 선택하세요',
              disabled: Boolean(!state.selectedDiseaseGroup),
              name: 'selectedTargetDiseaseTypes',
            },
          ]}
          isEssential={true}
        />
        <SingleSelectDropdown
          text="개발 상태"
          dropdownOptions={processStatusOptions}
          attributeName="krName"
          selectedItem={state.processStatus}
          setItem={changeState}
          dependentDropdownOptions={developmentStepOptions}
          dependentSelectedItem={state.developmentStep}
          setDependentItem={changeState}
          dependentAttributeName="krName"
          name="processStatus"
          dependentname="developmentStep"
        />
        {(isProcessStatusDeveloping || isProcessStatusMarket) && (
          <CheckBoxSelect
            text={`${isProcessStatusDeveloping ? '모집' : '판매'} 국가`}
            checkBoxList={state.selectedSaleCountries}
            setCheckBoxList={changeState}
            name="selectedSaleCountries"
          />
        )}
        <TinymceInput
          content={state.content}
          setContent={changeState}
          windowId={treatmentStudyId}
          setUploadUrl={setUploadUrl}
          onBlur={renewalImages}
          setImageLoading={setImageLoading}
          contentLinkData={{
            dictionaryInfo,
            diseaseTypeInfo: [],
            dnaInfo: [],
            setState,
          }}
          name="content"
        />
        <MultiSelectDropdown
          text="본문 링크"
          data={[
            {
              option: dictionaryInfo,
              selectedOption: state.selectedDictionaries,
              setOption: changeState,
              optionName: '용어',
              name: 'selectedDictionaries',
            },
            {
              optionName: '질환',
              disabled: true,
            },
            {
              optionName: '유전자',
              disabled: true,
            },
          ]}
          isEssential={true}
        />
        <MutateButton
          content={state.content}
          elementId={treatmentStudyId}
          create={putTreatmentStudy}
          update={changeTreatementStudy}
          remove={eraseTreatmentStudy}
          initialPhotos={initialPhotos}
          addedPhotos={addedPhotos}
          setEditorRemoveArray={setArrayToDelete}
          isDeleteImageExist={isDeleteImageExist}
          setIsCanceled={setIsCanceled}
        />
      </Form>
    </LoadingOverlay>
  );
};
export default TreatmentStudyWindow;
