import React, {
  useEffect,
  useState,
  useRef,
  useCallback,
  useReducer,
} from 'react';
import { Form, Spinner } from 'react-bootstrap';
import { parse } from 'qs';
import { useSelector, useDispatch, shallowEqual } from 'react-redux';
import LoadingOverlay from 'react-loading-overlay';
import WindowHeader from '../../components/windowHeader';
import { diseaseTypeCreators } from '../../../store/reducers/diseaseType.reducer';
import { dnaCreators } from '../../../store/reducers/dna.reducer';
import { healthProfileCreators } from '../../../store/reducers/healthProfile.reducer';
import MultiSelectDropdown from '../../components/multiSelectDropdown';
import TextInput from '../../components/textInput';
import SingleSwitchInput from '../../components/singleSwitchInput';
import SingleCheckInput from '../../components/singleCheckInput';
import DatePickerInput from '../../components/datePickerInput';
import MutateButton from '../../components/mutateButton';
import ElementLoading from '../../components/elementLoading';
import TinymceInput from '../../components/tinymceInput';

import {
  fetchEvent,
  postEvent,
  patchEvent,
  deleteEvent,
} from '../../../services/eventService';
import { useFetch, useMutate } from '../../../hooks/useRequest';
import DistinctImage from '../../components/distinctImage';
import { ALERT_MESSAGES } from '../../../assets/alert';
import {
  useDistinctFileUpload,
  useDistinctFileDelete,
} from '../../util/useDistinctImage';

const EventWindow = (props) => {
  const dispatch = useDispatch();
  const { diseaseTypeInfo, dnas, healthProfileInfo } = useSelector((state) => {
    return {
      diseaseTypeInfo: state?.diseaseTypeReducer?.diseaseTypes?.data?.map(
        ({ id, krName }) =>
          ({
            id,
            krName,
          } ?? null),
      ),
      dnas: state.dnaReducer?.dna?.data,
      healthProfileInfo: state?.healthProfileReducer?.healthProfile?.data?.map(
        ({ id, type }) =>
          ({
            id,
            name: type,
          } ?? null),
      ),
    };
  }, shallowEqual);

  const { search } = props.location;
  const [windowId, setWindowId] = useState('');
  const [eventId, setEventId] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [initialPhoto, setInitialPhoto] = useState(null);
  const [windowClose, setWindowClose] = useState(false);
  const [distinctFileInputEvent, setDistinctFileInputEvent] = useState(null);
  const [distinctImageToDelete, setDistinctImageToDelete] = useState(null);
  const [imageLoading, setImageLoading] = useState(false);
  const [isCanceled, setIsCanceled] = useState(false);
  const rangeToNumber = useCallback(
    (range) => (range ? Number(range) : null), // '0' -> 0, '' -> null
    [],
  );

  const contentChange = useRef(false);
  const didCancel = useRef(false);
  const fileUploadReady = useRef(false);
  const removeDistinctImageByButton = useRef(false);
  const deleteDone = useRef(false);
  const isDeleteImageExist = useRef(null);
  const distinctImageType = useRef(null);
  const initialState = {
    projectTitle: null,
    title: null,
    mainThumbnail: null,
    subThumbnail: null,
    backgroundImg: null,
    shareLink: '',
    startDate: null,
    endDate: null,
    capacity: null,
    isFinish: false,
    point: null,
    description: null,
    content: null,
    selectedDiseaseTypes: [],
    subDescription: null,
    bannerTextColor: null,
    bannerSubTextColor: null,
    bannerButtonBgColor: null,
    bannerButtonTextColor: null,

    isDisease: false,
    diseaseTitle: null,
    isDiseaseInfo: false,
    diseaseInfoTitle: null,
    diseaseInfoContent: null,
    diseaseOrder: null,

    isHospital: false,
    hospitalTitle: null,
    isHospitalInfo: false,
    hospitalInfoTitle: null,
    hospitalInfoContent: null,
    hospitalOrder: null,

    isDna: false,
    dnaTitle: null,
    dnaMin: null,
    dnaMax: null,
    selectedDnas: [],
    selectedRequiredDnas: [],
    isDnaInfo: false,
    dnaInfoTitle: null,
    dnaInfoContent: null,
    dnaOrder: null,

    isHealth: false,
    healthTitle: null,
    healthMin: null,
    healthMax: null,
    selectedHealth: [],
    selectedRequiredHealth: [],
    isHealthInfo: false,
    healthInfoTitle: null,
    healthInfoContent: null,
    healthOrder: null,
  };

  const stateReducer = (prevState, updatedProperty) => ({
    ...prevState,
    ...updatedProperty,
  });

  const [state, setState] = useReducer(stateReducer, initialState);

  const dnaAnalysisItems = [];
  state?.selectedDnas?.forEach((d1) => {
    d1.required =
      state?.selectedRequiredDnas?.some((d2) => d1.id === d2.id) ?? false;
    dnaAnalysisItems.push(d1);
  });

  const healthItems = [];
  state?.selectedHealth?.forEach((d1) => {
    d1.required =
      state?.selectedRequiredHealth?.some((d2) => d1.id === d2.id) ?? false;
    healthItems.push(d1);
  });

  const postPatchData = {
    isFinish: state.isFinish,
    title: state.title,
    content: state.content,
    description: state.description,
    mainThumbnail: state.mainThumbnail,
    subThumbnail: state.subThumbnail,
    backgroundImage: state.backgroundImg,
    shareLink: state.shareLink,
    startDate: state.startDate,
    endDate: state.endDate,
    capacity: state.capacity,
    point: state.point,
    projectTitle: state.projectTitle,
    subDescription: state.subDescription,
    bannerStyle: {
      text: { ...(state.bannerTextColor && { color: state.bannerTextColor }) },
      subText: {
        ...(state.bannerSubTextColor && { color: state.bannerSubTextColor }),
      },
      buttonBg: {
        ...(state.bannerButtonBgColor && { color: state.bannerButtonBgColor }),
      },
      buttonText: {
        ...(state.bannerButtonTextColor && {
          color: state.bannerButtonTextColor,
        }),
      },
    },

    diseaseTypeIds: state.selectedDiseaseTypes?.map(
      (diseaseType) => diseaseType.id,
    ),
    eventConditions: {
      diseaseTypeCertification: state?.isDisease && {
        order: rangeToNumber(state.diseaseOrder),
        title: state?.diseaseTitle,
        hasGuide: state?.isDiseaseInfo,
        guideTitle: state?.diseaseInfoTitle,
        guideContent: state?.diseaseInfoContent,
      },
      hospitalRecord: state?.isHospital && {
        order: rangeToNumber(state.hospitalOrder),
        title: state?.hospitalTitle,
        hasGuide: state.isHospitalInfo,
        guideTitle: state.hospitalInfoTitle,
        guideContent: state.hospitalInfoContent,
      },
      dnaAnalysis: state?.isDna && {
        order: rangeToNumber(state.dnaOrder),
        title: state?.dnaTitle,
        min: rangeToNumber(state?.dnaMin),
        max: rangeToNumber(state?.dnaMax),
        items: dnaAnalysisItems,
        hasGuide: state.isDnaInfo,
        guideTitle: state.dnaInfoTitle,
        guideContent: state.dnaInfoContent,
      },
      healthProfile: state?.isHealth && {
        order: rangeToNumber(state.healthOrder),
        title: state?.healthTitle,
        min: rangeToNumber(state?.healthMin),
        max: rangeToNumber(state?.healthMax),
        items: healthItems,
        hasGuide: state.isHealthInfo,
        guideTitle: state.healthInfoTitle,
        guideContent: state.healthInfoContent,
      },
    },
  };

  const { data: fetchEventData, call: callEvent } = useFetch(
    null,
    fetchEvent,
    eventId,
  );

  const { mutate: putEvent, done: isPosted } = useMutate(
    postEvent,
    postPatchData,
  );

  const { mutate: changeEvent, done: isPatched } = useMutate(
    patchEvent,
    eventId,
    postPatchData,
  );

  const { mutate: eraseEvent, done: isDeleted } = useMutate(
    deleteEvent,
    eventId,
  );

  const {
    publicUrl: distinctPublicUrl,
    done: distinctImageUploadDone,
    imageUpload: distinctImageUpload,
    initialize: distinctUploadInitialize,
  } = useDistinctFileUpload(distinctFileInputEvent);

  const {
    done: distinctImageDeleteDone,
    imageDelete: distinctImageDelete,
    initialize: distinctDeleteInitialize,
  } = useDistinctFileDelete(distinctImageToDelete);

  const message = isPosted
    ? ALERT_MESSAGES.CREATE
    : isPatched
    ? ALERT_MESSAGES.UPDATE
    : isDeleted
    ? ALERT_MESSAGES.DELETE
    : '';

  const isMutated = isPosted || isPatched || isDeleted;
  const windowCloseCondition = windowClose && distinctImageDeleteDone;

  const setupBeforeUnloadListener = (data) => {
    window.addEventListener('beforeunload', (event) => {
      event.preventDefault();
      return window?.opener?.postMessage(data, '/');
    });
  };
  useEffect(() => {
    const params = parse(search, {
      ignoreQueryPrefix: true,
    });
    setWindowId(params.id ? params.id : params.new);
    dispatch(diseaseTypeCreators.fetchAllDiseaseTypes.request());
    dispatch(dnaCreators.fetchAllDnas.request());
    dispatch(healthProfileCreators.fetchAllHealthProfiles.request());

    if (params.id) {
      setEventId(params.id);
    } else {
      setEventId('');
      setIsLoading(false);
    }
    setupBeforeUnloadListener(`close ${windowId}`);
  }, [search, windowId, dispatch]);

  useEffect(() => {
    if (eventId) callEvent();
  }, [eventId, callEvent]);

  useEffect(() => {
    if (fetchEventData && !didCancel.current) {
      setState({
        title: fetchEventData.title,
        mainThumbnail: fetchEventData.mainThumbnail,
        subThumbnail: fetchEventData.subThumbnail,
        backgroundImg: fetchEventData.backgroundImage,
        shareLink: fetchEventData.shareLink,
        startDate: new Date(fetchEventData.startDate),
        endDate: new Date(fetchEventData.endDate),
        capacity: fetchEventData.capacity,
        isFinish: fetchEventData.isFinish,
        point: fetchEventData.point,
        description: fetchEventData.description,
        content: fetchEventData.content,
        selectedDiseaseTypes: fetchEventData.diseaseTypes,
        projectTitle: fetchEventData.projectTitle,
        subDescription: fetchEventData.subDescription,
        bannerTextColor: fetchEventData.bannerStyle.text?.color,
        bannerSubTextColor: fetchEventData.bannerStyle.subText?.color,
        bannerButtonBgColor: fetchEventData.bannerStyle.buttonBg?.color,
        bannerButtonTextColor: fetchEventData.bannerStyle.buttonText?.color,

        isDisease: Boolean(
          fetchEventData?.eventConditions?.diseaseTypeCertification,
        ),
        diseaseTitle:
          fetchEventData?.eventConditions?.diseaseTypeCertification?.title,
        isDiseaseInfo:
          fetchEventData?.eventConditions?.diseaseTypeCertification?.hasGuide,
        diseaseInfoTitle:
          fetchEventData?.eventConditions?.diseaseTypeCertification?.guideTitle,
        diseaseInfoContent:
          fetchEventData?.eventConditions?.diseaseTypeCertification
            ?.guideContent,
        diseaseOrder:
          fetchEventData?.eventConditions?.diseaseTypeCertification?.order,

        isHospital: Boolean(fetchEventData?.eventConditions?.hospitalRecord),
        hospitalTitle: fetchEventData?.eventConditions?.hospitalRecord?.title,
        isHospitalInfo:
          fetchEventData?.eventConditions?.hospitalRecord?.hasGuide,
        hospitalInfoTitle:
          fetchEventData?.eventConditions?.hospitalRecord?.guideTitle,
        hospitalInfoContent:
          fetchEventData?.eventConditions?.hospitalRecord?.guideContent,
        hospitalOrder: fetchEventData?.eventConditions?.hospitalRecord?.order,

        isDna: Boolean(fetchEventData?.eventConditions?.dnaAnalysis),
        dnaTitle: fetchEventData?.eventConditions?.dnaAnalysis?.title,
        dnaMin: fetchEventData?.eventConditions?.dnaAnalysis?.min,
        dnaMax: fetchEventData?.eventConditions?.dnaAnalysis?.max,
        selectedDnas: fetchEventData?.eventConditions?.dnaAnalysis?.items ?? [],
        selectedRequiredDnas: (
          fetchEventData?.eventConditions?.dnaAnalysis?.items ?? []
        )
          .map(({ id, name, required }) =>
            required
              ? {
                  id,
                  name,
                }
              : null,
          )
          .filter(Boolean),
        isDnaInfo: fetchEventData?.eventConditions?.dnaAnalysis?.hasGuide,
        dnaInfoTitle: fetchEventData?.eventConditions?.dnaAnalysis?.guideTitle,
        dnaInfoContent:
          fetchEventData?.eventConditions?.dnaAnalysis?.guideContent,
        dnaOrder: fetchEventData?.eventConditions?.dnaAnalysis?.order,

        isHealth: Boolean(fetchEventData?.eventConditions?.healthProfile),
        healthTitle: fetchEventData?.eventConditions?.healthProfile?.title,
        healthMin: fetchEventData?.eventConditions?.healthProfile?.min,
        healthMax: fetchEventData?.eventConditions?.healthProfile?.max,
        selectedHealth:
          fetchEventData?.eventConditions?.healthProfile?.items ?? [],
        selectedRequiredHealth: (
          fetchEventData?.eventConditions?.healthProfile?.items ?? []
        )
          .map(({ id, name, required }) =>
            required
              ? {
                  id,
                  name,
                }
              : null,
          )
          .filter(Boolean),
        isHealthInfo: fetchEventData?.eventConditions?.healthProfile?.hasGuide,
        healthInfoTitle:
          fetchEventData?.eventConditions?.healthProfile?.guideTitle,
        healthInfoContent:
          fetchEventData?.eventConditions?.healthProfile?.guideContent,
        healthOrder: fetchEventData?.eventConditions?.healthProfile?.order,
      });
      setInitialPhoto([
        fetchEventData.mainThumbnail,
        fetchEventData.subThumbnail,
        fetchEventData.backgroundImage,
      ]);
      didCancel.current = true;
      setIsLoading(false);
    } else if (eventId === '' && !didCancel.current) {
      didCancel.current = true;
      setIsLoading(false);
    }
  }, [fetchEventData, eventId]);

  const figureFileInput = async (e, type) => {
    distinctImageType.current = type;

    e.persist();
    if (e.target.files[0] !== undefined) {
      setDistinctFileInputEvent(e);
      fileUploadReady.current = true;
      setImageLoading(true);
    }
  };

  useEffect(() => {
    if (distinctFileInputEvent && fileUploadReady.current) {
      distinctImageUpload();
      fileUploadReady.current = false;
    }
  }, [distinctFileInputEvent, distinctImageUpload]);
  useEffect(() => {
    if (
      distinctImageToDelete?.length > 0 &&
      !distinctImageDeleteDone &&
      !deleteDone.current
    ) {
      distinctImageDelete();
      setState({ [distinctImageType.current]: null });
      setWindowClose(true);
      deleteDone.current = true;
      distinctImageType.current = null;
    }
  }, [distinctImageDelete, distinctImageDeleteDone, distinctImageToDelete]);

  const closeWindowAfterAlert = useCallback(() => {
    if (isMutated || isCanceled) {
      setWindowClose(false);
      setDistinctImageToDelete(null);
      if (isMutated) alert(message);
      window.close();
    }
  }, [isMutated, isCanceled, message]);

  useEffect(() => {
    if (!isDeleteImageExist.current || windowCloseCondition) {
      closeWindowAfterAlert();
    }
  }, [windowCloseCondition, closeWindowAfterAlert]);

  useEffect(() => {
    if (distinctImageUploadDone && distinctPublicUrl) {
      setState({ [distinctImageType.current]: distinctPublicUrl });
      distinctUploadInitialize();
      setImageLoading(false);
      distinctImageType.current = null;
    }
  }, [distinctImageUploadDone, distinctPublicUrl, distinctUploadInitialize]);

  const readyToDeleteDistinctImage = (type, image) => {
    removeDistinctImageByButton.current = true;
    const splittedUrl = image.split('/');
    const deleteImage = `${splittedUrl[splittedUrl.length - 2]}/${
      splittedUrl[splittedUrl.length - 1]
    }`;
    distinctImageType.current = type;
    setDistinctImageToDelete(deleteImage);
  };

  useEffect(() => {
    if (distinctImageDeleteDone && removeDistinctImageByButton.current) {
      distinctDeleteInitialize();
      removeDistinctImageByButton.current = false;
      setDistinctImageToDelete(null);
      deleteDone.current = false;
    }
  }, [distinctDeleteInitialize, distinctImageDeleteDone]);

  const changeState = useCallback((e) => {
    setState({ [e.target.name]: e.target.value });
    contentChange.current = true;
  }, []);

  const checkState = ({ target: value }) => {
    setState({ [value?.name]: value?.checked });
  };

  if (isLoading) return <ElementLoading type="병원 검사" />;
  return (
    <LoadingOverlay
      active={imageLoading}
      spinner={<Spinner animation="border" variant="info" />}
      text={<p>이미지를 업로드 하는 중</p>}
    >
      <WindowHeader title="이벤트" />
      <Form className="windowForm">
        <TextInput
          text="프로젝트명"
          value={state.projectTitle}
          onChange={changeState}
          isEssential={true}
          name="projectTitle"
        />
        <TextInput
          text="배너명"
          value={state.title}
          onChange={changeState}
          isEssential={true}
          name="title"
        />
        <div className="fade_in_box">
          이벤트명 [enter]를 입력하면 줄바꿈이 됩니다. ex) 유전성
          망막질환[enter]치료를 위한 여정{' '}
        </div>
        <DistinctImage
          text="홈 배너"
          imageFileInput={(e) => figureFileInput(e, 'mainThumbnail')}
          images={state.mainThumbnail}
          deleteImage={readyToDeleteDistinctImage}
          name="mainThumbnail"
        />
        <DistinctImage
          text="나의노트 배너"
          imageFileInput={(e) => figureFileInput(e, 'subThumbnail')}
          images={state.subThumbnail}
          deleteImage={readyToDeleteDistinctImage}
          name="subThumbnail"
        />
        <DistinctImage
          text="페이지 이미지"
          imageFileInput={(e) => figureFileInput(e, 'backgroundImg')}
          images={state.backgroundImg}
          deleteImage={readyToDeleteDistinctImage}
          name="backgroundImg"
        />
        <div className="fade_in_box">페이지이미지 크기는 240 * 132 입니다.</div>
        <TextInput
          text="공유링크"
          value={state.shareLink}
          onChange={changeState}
          placeholder="미입력 시 자동으로 생성됩니다"
          name="shareLink"
        />
        <DatePickerInput
          text="시작일자"
          value={state.startDate}
          setDate={changeState}
          isEssential={true}
          name="startDate"
        />
        <DatePickerInput
          text="종료일자"
          value={state.endDate}
          setDate={changeState}
          isEssential={true}
          name="endDate"
        />
        <TextInput
          text="정원"
          value={state.capacity}
          onChange={changeState}
          isEssential={true}
          name="capacity"
        />
        <SingleCheckInput
          text="마감"
          value={state.isFinish}
          onChange={checkState}
          isEssential={true}
          name="isFinish"
        />
        <TextInput
          text="HP"
          value={state.point}
          onChange={changeState}
          isEssential={true}
          name="point"
        />
        <MultiSelectDropdown
          text="질환"
          isEssential={true}
          data={[
            {
              option: diseaseTypeInfo,
              selectedOption: state.selectedDiseaseTypes,
              setOption: changeState,
              optionName: '대상 질환(복수 선택 가능)을 선택하세요',
              name: 'selectedDiseaseTypes',
            },
          ]}
        />
        <TextInput
          text="본문제목"
          value={state.description}
          onChange={changeState}
          isEssential={true}
          name="description"
        />
        <div className="fade_in_box">
          본문제목에 [enter]를 입력하면 줄바꿈이 됩니다. ex) 유전성
          망막질환[enter]치료를 위한 여정 <br />
          강조텍스트는 {'<b>강조할텍스트</b>'}를 입력하면 됩니다.
        </div>
        <TextInput
          text="추가정보"
          value={state.subDescription}
          onChange={changeState}
          isEssential={true}
          name="subDescription"
        />
        <TinymceInput
          content={state.content}
          setContent={changeState}
          setImageLoading={setImageLoading}
          windowId={eventId}
          name="content"
        />
        <Form.Group>
          <Form.Label column sm="3" md="2" xl="1">
            스타일 추가
          </Form.Label>
        </Form.Group>
        <div
          style={{
            border: '2px solid green',
            padding: '10px 0px',
            margin: '10px',
          }}
        >
          <TextInput
            text="배너 텍스트 컬러값"
            value={state.bannerTextColor}
            onChange={changeState}
            isEssential={true}
            name="bannerTextColor"
          />
          <TextInput
            text="배너 SubText 컬러값"
            value={state.bannerSubTextColor}
            onChange={changeState}
            isEssential={true}
            name="bannerSubTextColor"
          />
          <TextInput
            text="배너 버튼 배경 컬러값"
            value={state.bannerButtonBgColor}
            onChange={changeState}
            isEssential={true}
            name="bannerButtonBgColor"
          />
          <TextInput
            text="배너 버튼 텍스트 컬러값"
            value={state.bannerButtonTextColor}
            onChange={changeState}
            isEssential={true}
            name="bannerButtonTextColor"
          />
        </div>

        <Form.Group>
          <Form.Label column sm="3" md="2" xl="1">
            HP 지급 조건
          </Form.Label>
        </Form.Group>
        <div
          style={{
            border: '2px solid #B5BFF5',
            padding: '10px 0px',
            margin: '10px',
          }}
        >
          <SingleSwitchInput
            text="질환 인증"
            value={state.isDisease}
            onChange={checkState}
            isEssential={true}
            name="isDisease"
          />
          {state.isDisease && (
            <>
              <TextInput
                text="우선순위"
                value={state.diseaseOrder}
                onChange={changeState}
                isEssential={true}
                name="diseaseOrder"
              />
              <TextInput
                text="조건 제목"
                value={state.diseaseTitle}
                onChange={changeState}
                isEssential={true}
                name="diseaseTitle"
              />
              <SingleSwitchInput
                text="안내 페이지"
                value={state.isDiseaseInfo}
                onChange={checkState}
                isEssential={true}
                name="isDiseaseInfo"
              />
              {state.isDiseaseInfo && (
                <>
                  <TextInput
                    text="안내 제목"
                    value={state.diseaseInfoTitle}
                    onChange={changeState}
                    isEssential={true}
                    name="diseaseInfoTitle"
                  />
                  <TinymceInput
                    content={state.diseaseInfoContent}
                    setContent={changeState}
                    setImageLoading={setImageLoading}
                    windowId={eventId}
                    name="diseaseInfoContent"
                  />
                </>
              )}
            </>
          )}
        </div>

        <div
          style={{
            border: '2px solid #84F59D',
            padding: '10px 0px',
            margin: '10px',
          }}
        >
          <SingleSwitchInput
            text="병원 검사"
            value={state.isHospital}
            onChange={checkState}
            isEssential={true}
            name="isHospital"
          />
          {state.isHospital && (
            <>
              <TextInput
                text="우선순위"
                value={state.hospitalOrder}
                onChange={changeState}
                isEssential={true}
                name="hospitalOrder"
              />
              <TextInput
                text="조건 제목"
                value={state.hospitalTitle}
                onChange={changeState}
                isEssential={true}
                name="hospitalTitle"
              />
              <SingleSwitchInput
                text="안내 페이지"
                value={state.isHospitalInfo}
                onChange={checkState}
                isEssential={true}
                name="isHospitalInfo"
              />
              {state.isHospitalInfo && (
                <>
                  <TextInput
                    text="안내 제목"
                    value={state.hospitalInfoTitle}
                    onChange={changeState}
                    isEssential={true}
                    name="hospitalInfoTitle"
                  />
                  <TinymceInput
                    content={state.hospitalInfoContent}
                    setContent={changeState}
                    setImageLoading={setImageLoading}
                    windowId={eventId}
                    name="hospitalInfoContent"
                  />
                </>
              )}
            </>
          )}
        </div>

        <div
          style={{
            border: '2px solid #F58491',
            padding: '10px 0px',
            margin: '10px',
          }}
        >
          <SingleSwitchInput
            text="유전자 검사"
            value={state.isDna}
            onChange={checkState}
            isEssential={true}
            name="isDna"
          />
          {state.isDna && (
            <>
              <TextInput
                text="우선순위"
                value={state.dnaOrder}
                onChange={changeState}
                isEssential={true}
                name="dnaOrder"
              />
              <TextInput
                text="조건 제목"
                value={state.dnaTitle}
                onChange={changeState}
                isEssential={true}
                name="dnaTitle"
              />
              <TextInput
                text="x개 이상"
                value={state.dnaMin}
                onChange={changeState}
                isEssential={true}
                name="dnaMin"
              />
              <TextInput
                text="x개 이하"
                value={state.dnaMax}
                onChange={changeState}
                isEssential={true}
                name="dnaMax"
              />
              <MultiSelectDropdown
                text="유전자검사"
                isEssential={true}
                data={[
                  {
                    option: dnas,
                    selectedOption: state.selectedDnas,
                    setOption: changeState,
                    optionName: '유전자검사(복수 선택 가능)을 선택하세요',
                    name: 'selectedDnas',
                  },
                ]}
              />
              <MultiSelectDropdown
                text="필수유전자검사"
                isEssential={true}
                data={[
                  {
                    option: dnas,
                    selectedOption: state.selectedRequiredDnas,
                    setOption: changeState,
                    optionName: '필수 유전자검사(복수 선택 가능)을 선택하세요',
                    name: 'selectedRequiredDnas',
                  },
                ]}
              />
              <SingleSwitchInput
                text="안내 페이지"
                value={state.isDnaInfo}
                onChange={checkState}
                isEssential={true}
                name="isDnaInfo"
              />
              {state.isDnaInfo && (
                <>
                  <TextInput
                    text="안내 제목"
                    value={state.dnaInfoTitle}
                    onChange={changeState}
                    isEssential={true}
                    name="dnaInfoTitle"
                  />
                  <TinymceInput
                    content={state.dnaInfoContent}
                    setContent={changeState}
                    setImageLoading={setImageLoading}
                    windowId={eventId}
                    name="dnaInfoContent"
                  />
                </>
              )}
            </>
          )}
        </div>

        <div
          style={{
            border: '2px solid #F5DD90',
            padding: '10px 0px',
            margin: '10px',
          }}
        >
          <SingleSwitchInput
            text="건강 설문"
            value={state.isHealth}
            onChange={checkState}
            isEssential={true}
            name="isHealth"
          />
          {state.isHealth && (
            <>
              <TextInput
                text="우선순위"
                value={state.healthOrder}
                onChange={changeState}
                isEssential={true}
                name="healthOrder"
              />
              <TextInput
                text="조건 제목"
                value={state.healthTitle}
                onChange={changeState}
                isEssential={true}
                name="healthTitle"
              />
              <TextInput
                text="x개 이상"
                value={state.healthMin}
                onChange={changeState}
                isEssential={true}
                name="healthMin"
              />
              <TextInput
                text="x개 이하"
                value={state.healthMax}
                onChange={changeState}
                isEssential={true}
                name="healthMax"
              />
              <MultiSelectDropdown
                text="건강설문"
                isEssential={true}
                data={[
                  {
                    option: healthProfileInfo,
                    selectedOption: state.selectedHealth,
                    setOption: changeState,
                    optionName: '건강설문(복수 선택 가능)을 선택하세요',
                    name: 'selectedHealth',
                  },
                ]}
              />
              <MultiSelectDropdown
                text="필수건강설문"
                isEssential={true}
                data={[
                  {
                    option: healthProfileInfo,
                    selectedOption: state.selectedRequiredHealth,
                    setOption: changeState,
                    optionName: '필수 건강설문(복수 선택 가능)을 선택하세요',
                    name: 'selectedRequiredHealth',
                  },
                ]}
              />
              <SingleSwitchInput
                text="안내 페이지"
                value={state.isHealthInfo}
                onChange={checkState}
                isEssential={true}
                name="isHealthInfo"
              />
              {state.isHealthInfo && (
                <>
                  <TextInput
                    text="안내 제목"
                    value={state.healthInfoTitle}
                    onChange={changeState}
                    isEssential={true}
                    name="healthInfoTitle"
                  />
                  <TinymceInput
                    content={state.healthInfoContent}
                    setContent={changeState}
                    setImageLoading={setImageLoading}
                    windowId={eventId}
                    name="healthInfoContent"
                  />
                </>
              )}
            </>
          )}
        </div>

        <MutateButton
          elementId={eventId}
          create={putEvent}
          update={changeEvent}
          remove={eraseEvent}
          setDistinctRemoveImage={setDistinctImageToDelete}
          initialPhoto={initialPhoto}
          photo={[
            state.mainThumbnail,
            state.subThumbnail,
            state.backgroundImg,
          ].filter(Boolean)}
          imageType="figure"
          isDeleteImageExist={isDeleteImageExist}
          setIsCanceled={setIsCanceled}
        />
      </Form>
    </LoadingOverlay>
  );
};

export default EventWindow;
