import React from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';

import {
  Admin,
  AdminSetting,
  ClinicalTrial,
  ClinicalTrialCounseling,
  ClinicalTrialCounselingDetail,
  ClinicalTrialCounselingSetting,
  Contents,
  ContentsWebView,
  Dashboard,
  DashboardUserData,
  Dictionary,
  DiseaseInfo,
  DiseaseType,
  DiseaseTypeCertificationsRequest,
  DiseaseTypeClinicalTrial,
  Dna,
  DnaCounselingRequest,
  DnaCounselingRequestDetail,
  DnaCounselingSetting,
  DoctorProfile,
  EntryModal,
  Event,
  EventParticipants,
  GeneResearch,
  GeneResearchDetail,
  HealthProfile,
  HealthProfileUsers,
  HospitalExaminationRecordsRequest,
  HospitalRecord,
  IndividualNotification,
  IpAllow,
  Login,
  Main,
  MediaMonitorConfig,
  MediaMonitorNews,
  MutationDna,
  MutationDnaReport,
  MutationDnaReportRegisterRequest,
  MutationDnaReportRegisterRequestDetail,
  Notice,
  Notification,
  PageDoesntExist,
  PapAuditDetail,
  PapAuditList,
  PapProjectList,
  PatientGroup,
  PatientGroupUser,
  Popup,
  Rehabilitation,
  RemoteConfig,
  Research,
  RssFeed,
  SupportVersion,
  Tag,
  TranslateWord,
  TreatmentStudy,
  TreatmentStudyTimeline,
  User,
  UserDetail,
  UserWithdrawal,
} from './views';

import 'semantic-ui-css/components/dropdown.min';
import 'semantic-ui-css/components/dropdown.min.css';
import 'semantic-ui-css/components/transition.min';
import 'semantic-ui-css/components/transition.min.css';
import './globals';
import ProtectedRoute from './protectedRoute';
import './public/css/rarenote.css';

import PAGE_URL from './assets/pageUrl';
import * as permissions from './util/permission';
import mediaMonitorConfigSetting from './views/pages/mediaMonitor/mediaMonitorConfigSetting';
import {
  AdminWindow,
  ClinicalTrialWindow,
  ContentsReservationWindow,
  ContentsWindow,
  DictionaryWindow,
  DiseaseInfoWindow,
  DiseaseTypeWindow,
  DnaWindow,
  DoctorProfileWindow,
  EntryModalWindow,
  EventWindow,
  HealthProfileOptionWindow,
  HealthProfileQuestionWindow,
  HealthProfileWindow,
  HospitalExaminationWindow,
  IpAllowWindow,
  MediaMonitorConfigWindow,
  MediaMonitorLetterWindow,
  MediaMonitorNewsWindow,
  MediaMonitorPreviewsWindow,
  MutationDnaReportWindow,
  MutationDnaWindow,
  NoticeWindow,
  PapProjectWindow,
  PapTermWindow,
  PatientGroupWindow,
  PopupWindow,
  RehabilitationDetailWindow,
  RehabilitationWindow,
  RemoteConfigWindow,
  ResearchWindow,
  RssFeedWindow,
  SupportVersionWindow,
  TagWindow,
  TranslateWordWindow,
  TreatmentNewsWindow,
  TreatmentStudyTimelineWindow,
  TreatmentStudyWindow,
  UserHospitalExaminationWindow,
  UserdiseaseTypeCertificationWindow,
} from './window';
import WindowManager from './window/windowManager';

const App = () => {
  // declare dispatch function & variables for fetching data from store
  const metaTitle =
    process.env.REACT_APP_DEPLOY === 'production'
      ? `운영`
      : process.env.REACT_APP_DEPLOY === 'staging'
      ? `스테이지`
      : `개발`;
  document.title = `[${metaTitle}] 레어노트 어드민`;
  return (
    <BrowserRouter basename={process.env.PUBLIC_URL}>
      <WindowManager />
      <Switch>
        <Route exact path={`/${PAGE_URL.LOGIN}`} component={Login} />
        <ProtectedRoute exact path="/" permission={permissions.ADMIN_LOGIN} />
        <ProtectedRoute
          exact
          path={`/${PAGE_URL.MAIN}`}
          permission={permissions.ADMIN_LOGIN}
          component={Main}
        />

        {/* 계정 */}
        <ProtectedRoute
          exact
          path={`/${PAGE_URL.ADMIN}`}
          permission={permissions.ACCOUNT_ADMIN}
          component={Admin}
        />
        <ProtectedRoute
          exact
          path={`/${PAGE_URL.ADMIN_WINDOW}`}
          permission={permissions.ACCOUNT_ADMIN}
          component={AdminWindow}
        />
        <ProtectedRoute
          exact
          path={`/${PAGE_URL.ADMIN_SETTING}`}
          permission={permissions.ACCOUNT_ADMIN}
          component={AdminSetting}
        />
        <ProtectedRoute
          exact
          path={`/${PAGE_URL.PATIENT_GROUP}`}
          permission={permissions.ACCOUNT_PATIENT_GROUP}
          component={PatientGroup}
        />
        <ProtectedRoute
          exact
          path={`/${PAGE_URL.PATIENT_GROUP_USER}`}
          permission={permissions.ACCOUNT_PATIENT_GROUP}
          component={PatientGroupUser}
        />
        <ProtectedRoute
          exact
          path={`/${PAGE_URL.PATIENT_GROUP_WINDOW}`}
          permission={permissions.ACCOUNT_PATIENT_GROUP}
          component={PatientGroupWindow}
        />
        <ProtectedRoute
          exact
          path={`/${PAGE_URL.USER}`}
          permission={permissions.ACCOUNT_USER}
          component={User}
        />
        <ProtectedRoute
          exact
          path={`/${PAGE_URL.USER_WINDOW}`}
          permission={permissions.ACCOUNT_USER}
          component={UserDetail}
        />
        <ProtectedRoute
          exact
          path={`/${PAGE_URL.USER_WITHDRAWAL}`}
          permission={permissions.WITHDRAWAL_USER}
          component={UserWithdrawal}
        />

        {/* 유전자 검사 */}
        <ProtectedRoute
          exact
          path={`/${PAGE_URL.DNA_COUNSELING}`}
          permission={permissions.DNA_COUNSELING}
          component={DnaCounselingRequest}
        />
        <ProtectedRoute
          exact
          path={`/${PAGE_URL.DNA_COUNSELING_SETTING}`}
          permission={permissions.DNA_COUNSELING}
          component={DnaCounselingSetting}
        />
        <ProtectedRoute
          exact
          path={`/${PAGE_URL.CLINICALTRIAL_COUNSELING}`}
          permission={permissions.CLINICALTRIAL_COUNSELING}
          component={ClinicalTrialCounseling}
        />
        <ProtectedRoute
          exact
          path={`/${PAGE_URL.CLINICALTRIAL_COUNSELING_SETTING}`}
          permission={permissions.CLINICALTRIAL_COUNSELING}
          component={ClinicalTrialCounselingSetting}
        />
        <ProtectedRoute
          exact
          path={`/${PAGE_URL.CLINICALTRIAL_COUNSELING_DETAIL}`}
          permission={permissions.CLINICALTRIAL_COUNSELING}
          component={ClinicalTrialCounselingDetail}
        />
        <ProtectedRoute
          exact
          path={`/${PAGE_URL.MUTATION_DNA_REPORT_REGISTER_REQUEST}`}
          permission={permissions.DNA_REPORT}
          component={MutationDnaReportRegisterRequest}
        />
        <ProtectedRoute
          exact
          path={`/${PAGE_URL.HOSPITALEXAMINATION_RECORDS_REQUEST}`}
          permission={permissions.DNA_REPORT}
          component={HospitalExaminationRecordsRequest}
        />
        <ProtectedRoute
          exact
          path={`/${PAGE_URL.DISEASETYPECERTIFICATION_REQUEST}`}
          permission={permissions.DNA_REPORT}
          component={DiseaseTypeCertificationsRequest}
        />
        <ProtectedRoute
          exact
          path={`/${PAGE_URL.MUTATION_DNA_REPORT_REGISTER_REQUEST_DETAIL}`}
          permission={permissions.DNA_REPORT}
          component={MutationDnaReportRegisterRequestDetail}
        />
        <ProtectedRoute
          exact
          path={`/${PAGE_URL.DNA_COUNSELING_DETAIL}`}
          permission={permissions.DNA_COUNSELING}
          component={DnaCounselingRequestDetail}
        />
        <ProtectedRoute
          exact
          path={`/${PAGE_URL.RESEARCH}`}
          permission={permissions.GENE_RESEARCH}
          component={Research}
        />
        <ProtectedRoute
          exact
          path={`/${PAGE_URL.RESEARCH_WINDOW}`}
          permission={permissions.GENE_RESEARCH}
          component={ResearchWindow}
        />
        <ProtectedRoute
          exact
          path={`/${PAGE_URL.GENE_RESEARCH}`}
          permission={permissions.GENE_RESEARCH}
          component={GeneResearch}
        />
        <ProtectedRoute
          exact
          path={`/${PAGE_URL.GENE_RESEARCH_DETAIL}`}
          permission={permissions.GENE_RESEARCH}
          component={GeneResearchDetail}
        />

        <ProtectedRoute
          exact
          path={`/${PAGE_URL.MUTATION_DNA_REPORT}`}
          permission={permissions.DNA_REPORT}
          component={MutationDnaReport}
        />
        <ProtectedRoute
          exact
          path={`/${PAGE_URL.MUTATION_DNA_REPORT_WINDOW}`}
          permission={permissions.DNA_REPORT}
          component={MutationDnaReportWindow}
        />
        <ProtectedRoute
          exact
          path={`/${PAGE_URL.MUTATION_DNA}`}
          permission={permissions.DNA_REPORT}
          component={MutationDna}
        />
        <ProtectedRoute
          exact
          path={`/${PAGE_URL.MUTATION_DNA_WINDOW}`}
          permission={permissions.DNA_REPORT}
          component={MutationDnaWindow}
        />
        <ProtectedRoute
          exact
          path={`/${PAGE_URL.DOCTORPROFILE}`}
          permission={permissions.DOCTORPROFILE}
          component={DoctorProfile}
        />
        <ProtectedRoute
          exact
          path={`/${PAGE_URL.DOCTORPROFILE_WINDOW}`}
          permission={permissions.DOCTORPROFILE}
          component={DoctorProfileWindow}
        />

        {/* 콘텐츠 */}
        <ProtectedRoute
          exact
          path={`/${PAGE_URL.TREATMENT_STUDY}`}
          permission={permissions.CONTENT_PRODUCT}
          component={TreatmentStudy}
        />
        <ProtectedRoute
          exact
          path={`/${PAGE_URL.TREATMENT_STUDY_TIMELINE}`}
          permission={permissions.CONTENT_PRODUCT}
          component={TreatmentStudyTimeline}
        />
        <ProtectedRoute
          exact
          path={`/${PAGE_URL.CONTENTS}`}
          permission={permissions.CONTENT_TREATMENT_NEWS}
          component={Contents}
        />
        <ProtectedRoute
          exact
          path={`/${PAGE_URL.CONTENTS_WINDOW}`}
          permission={permissions.CONTENT_TREATMENT_NEWS}
          component={ContentsWindow}
        />
        <ProtectedRoute
          exact
          path={`/${PAGE_URL.CONTENTS_RESERVATION_WINDOW}`}
          permission={permissions.CONTENT_TREATMENT_NEWS}
          component={ContentsReservationWindow}
        />
        <ProtectedRoute
          exact
          path={`/${PAGE_URL.DISEASEINFO}`}
          permission={permissions.CONTENT_TREATMENT_NEWS}
          component={DiseaseInfo}
        />
        <ProtectedRoute
          exact
          path={`/${PAGE_URL.DISEASEINFO_WINDOW}`}
          permission={permissions.CONTENT_TREATMENT_NEWS}
          component={DiseaseInfoWindow}
        />
        <ProtectedRoute
          exact
          path={`/${PAGE_URL.CONTENTS_WEB_VIEW}`}
          permission={permissions.CONTENT_WEB_VIEW}
          component={ContentsWebView}
        />
        <ProtectedRoute
          exact
          path={`/${PAGE_URL.DICTIONARY}`}
          permission={permissions.CONTENT_TERMS}
          component={Dictionary}
        />
        <ProtectedRoute
          exact
          path={`/${PAGE_URL.DNA}`}
          permission={permissions.CONTENT_DNA}
          component={Dna}
        />
        <ProtectedRoute
          exact
          path={`/${PAGE_URL.DISEASE_TYPE}`}
          permission={permissions.CONTENT_DISEASE}
          component={DiseaseType}
        />
        <ProtectedRoute
          exact
          path={`/${PAGE_URL.DISEASE_TYPE_CLINICAL_TRIAL}`}
          permission={permissions.CONTENT_DISEASE}
          component={DiseaseTypeClinicalTrial}
        />
        <ProtectedRoute
          exact
          path={`/${PAGE_URL.HOSPITAL_RECORD}`}
          permission={permissions.CONTENT_HOSPITAL_EXAMINATION}
          component={HospitalRecord}
        />
        <ProtectedRoute
          exact
          path={`/${PAGE_URL.HEALTH_PROFILE}`}
          permission={permissions.CONTENT_HEALTH_PROFILE}
          component={HealthProfile}
        />
        <ProtectedRoute
          exact
          path={`/${PAGE_URL.HEALTH_PROFILE_USERS}`}
          permission={permissions.ACCOUNT_USER}
          component={HealthProfileUsers}
        />
        <ProtectedRoute
          exact
          path={`/${PAGE_URL.ENTRY_MODAL}`}
          permission={permissions.ADMIN_LOGIN}
          component={EntryModal}
        />
        <ProtectedRoute
          exact
          path={`/${PAGE_URL.ENTRY_MODAL_WINDOW}`}
          permission={permissions.ADMIN_LOGIN}
          component={EntryModalWindow}
        />
        <ProtectedRoute
          exact
          path={`/${PAGE_URL.REHABILITATION}`}
          permission={permissions.CONTENT_REHABILITATION}
          component={Rehabilitation}
        />
        <ProtectedRoute
          exact
          path={`/${PAGE_URL.REHABILITATION_WINDOW}`}
          permission={permissions.CONTENT_REHABILITATION}
          component={RehabilitationWindow}
        />
        <ProtectedRoute
          exact
          path={`/${PAGE_URL.REHABILITATION_DETAIL_WINDOW}`}
          permission={permissions.CONTENT_REHABILITATION}
          component={RehabilitationDetailWindow}
        />
        <ProtectedRoute
          exact
          path={`/${PAGE_URL.RSS_FEED}`}
          permission={permissions.CONTENT_RSS_FEED}
          component={RssFeed}
        />
        <ProtectedRoute
          exact
          path={`/${PAGE_URL.RSS_FEED_WINDOW}`}
          permission={permissions.CONTENT_RSS_FEED}
          component={RssFeedWindow}
        />
        <ProtectedRoute
          exact
          path={`/${PAGE_URL.TOPIC_TAG}`}
          permission={permissions.TOPIC_TAG}
          component={Tag}
        />
        <ProtectedRoute
          exact
          path={`/${PAGE_URL.TRANSLATE_WORD}`}
          permission={permissions.TRANSLATE_WORD}
          component={TranslateWord}
        />

        {/* 공지 */}
        <ProtectedRoute
          exact
          path={`/${PAGE_URL.NOTICE}`}
          permission={permissions.MANAGE_NOTICE}
          component={Notice}
        />
        <ProtectedRoute
          exact
          path={`/${PAGE_URL.NOTIFICATION}`}
          permission={permissions.MANAGE_NOTIFICATION}
          component={Notification}
        />
        <ProtectedRoute
          exact
          path={`/${PAGE_URL.INDIVIDUAL_NOTIFICATION}`}
          permission={permissions.MANAGE_INDIVIDUAL_NOTIFICATION}
          component={IndividualNotification}
        />
        <ProtectedRoute
          exact
          path={`/${PAGE_URL.EVENT}`}
          permission={permissions.MANAGE_EVENT}
          component={Event}
        />
        <ProtectedRoute
          exact
          path={`/${PAGE_URL.EVENT_PARTICIPANTS}`}
          permission={permissions.MANAGE_EVENT}
          component={EventParticipants}
        />

        {/* 팝업 창 */}
        <ProtectedRoute
          path={`/${PAGE_URL.TREATMENT_STUDY_WINDOW}`}
          permission={permissions.CONTENT_PRODUCT}
          component={TreatmentStudyWindow}
        />
        <ProtectedRoute
          exact
          path={`/${PAGE_URL.TREATMENT_STUDY_TIMELINE_WINDOW}`}
          permission={permissions.CONTENT_PRODUCT}
          component={TreatmentStudyTimelineWindow}
        />
        <ProtectedRoute
          path={`/${PAGE_URL.TREATMENT_NEWS_WINDOW}`}
          permission={permissions.CONTENT_TREATMENT_NEWS}
          component={TreatmentNewsWindow}
        />
        <ProtectedRoute
          path={`/${PAGE_URL.DICTIONARY_WINDOW}`}
          permission={permissions.CONTENT_TERMS}
          component={DictionaryWindow}
        />
        <ProtectedRoute
          path={`/${PAGE_URL.DNA_WINDOW}`}
          permission={permissions.CONTENT_DNA}
          component={DnaWindow}
        />
        <ProtectedRoute
          path={`/${PAGE_URL.DISEASE_TYPE_WINDOW}`}
          permission={permissions.CONTENT_DISEASE}
          component={DiseaseTypeWindow}
        />
        <ProtectedRoute
          path={`/${PAGE_URL.NOTICE_WINDOW}`}
          permission={permissions.MANAGE_NOTICE}
          component={NoticeWindow}
        />
        <ProtectedRoute
          exact
          path={`/${PAGE_URL.HEALTH_PROFILE_WINDOW}`}
          permission={permissions.CONTENT_HEALTH_PROFILE}
          component={HealthProfileWindow}
        />
        <ProtectedRoute
          exact
          path={`/${PAGE_URL.HEALTH_PROFILE_QUESTION_WINDOW}`}
          permission={permissions.CONTENT_HEALTH_PROFILE}
          component={HealthProfileQuestionWindow}
        />
        <ProtectedRoute
          exact
          path={`/${PAGE_URL.HOSPITAL_EXAMINATION_WINDOW}`}
          permission={permissions.CONTENT_HOSPITAL_EXAMINATION}
          component={HospitalExaminationWindow}
        />
        <ProtectedRoute
          exact
          path={`/${PAGE_URL.USER_HOSPITAL_EXAMINATION_WINDOW}`}
          permission={permissions.HOSPITAL_EXAMINATION_RECORD}
          component={UserHospitalExaminationWindow}
        />
        <ProtectedRoute
          exact
          path={`/${PAGE_URL.USER_DISEASE_TYPE_CERTIFICATION_WINDOW}`}
          permission={permissions.HOSPITAL_EXAMINATION_RECORD}
          component={UserdiseaseTypeCertificationWindow}
        />
        <ProtectedRoute
          exact
          path={`/${PAGE_URL.HEALTH_PROFILE_OPTION_WINDOW}`}
          permission={permissions.CONTENT_HEALTH_PROFILE}
          component={HealthProfileOptionWindow}
        />
        <ProtectedRoute
          exact
          path={`/${PAGE_URL.EVENT_WINDOW}`}
          permission={permissions.MANAGE_EVENT}
          component={EventWindow}
        />
        <ProtectedRoute
          exact
          path={`/${PAGE_URL.TOPIC_TAG_WINDOW}`}
          permission={permissions.TOPIC_TAG}
          component={TagWindow}
        />
        <ProtectedRoute
          exact
          path={`/${PAGE_URL.TRANSLATE_WORD_WINDOW}`}
          permission={permissions.TRANSLATE_WORD}
          component={TranslateWordWindow}
        />

        {/* 대시보드 */}
        <ProtectedRoute
          exact
          path={`/${PAGE_URL.DASHBOARD}`}
          permission={permissions.DASHBOARD}
          component={Dashboard}
        />
        <ProtectedRoute
          exact
          path={`/${PAGE_URL.DASHBOARD_DATA}`}
          permission={permissions.ACCOUNT_USER}
          component={DashboardUserData}
        />

        {/* SupportVersion */}
        <ProtectedRoute
          exact
          path={`/${PAGE_URL.SUPPORT_VERSION}`}
          permission={permissions.SUPPORT_VERSION}
          component={SupportVersion}
        />
        <ProtectedRoute
          exact
          path={`/${PAGE_URL.SUPPORT_VERSION_WINDOW}`}
          permission={permissions.SUPPORT_VERSION}
          component={SupportVersionWindow}
        />
        <ProtectedRoute
          exact
          path={`/${PAGE_URL.POPUP}`}
          permission={permissions.MANAGE_POPUP}
          component={Popup}
        />
        <ProtectedRoute
          exact
          path={`/${PAGE_URL.POPUP_WINDOW}`}
          permission={permissions.MANAGE_POPUP}
          component={PopupWindow}
        />
        <ProtectedRoute
          exact
          path={`/${PAGE_URL.CLINICAL_TRIAL_WINDOW}`}
          permission={permissions.CONTENT_DISEASE}
          component={ClinicalTrialWindow}
        />
        <ProtectedRoute
          exact
          path={`/${PAGE_URL.CLINICAL_TRIAL}`}
          permission={permissions.ADMIN_LOGIN}
          component={ClinicalTrial}
        />
        <ProtectedRoute
          exact
          path={`/${PAGE_URL.IPALLOW}`}
          permission={permissions.IP_ALLOW}
          component={IpAllow}
        />
        <ProtectedRoute
          exact
          path={`/${PAGE_URL.IPALLOW_WINDOW}`}
          permission={permissions.IP_ALLOW}
          component={IpAllowWindow}
        />
        <ProtectedRoute
          exact
          path={`/${PAGE_URL.REMOTE_CONFIG}`}
          permission={permissions.MANAGE_REMOTE_CONFIG}
          component={RemoteConfig}
        />
        <ProtectedRoute
          exact
          path={`/${PAGE_URL.REMOTE_CONFIG_WINDOW}`}
          permission={permissions.MANAGE_REMOTE_CONFIG}
          component={RemoteConfigWindow}
        />
        {/* PAP */}
        <ProtectedRoute
          path={`/${PAGE_URL.PAP}`}
          permission={permissions.PAP}
          component={PapProjectList}
        />
        <ProtectedRoute
          path={`/${PAGE_URL.PAP_PROJECT_WINDOW}`}
          permission={permissions.PAP}
          component={PapProjectWindow}
        />
        <ProtectedRoute
          path={`/${PAGE_URL.PAP_TERM_WINDOW}`}
          permission={permissions.PAP}
          component={PapTermWindow}
        />
        <ProtectedRoute
          path={`/${PAGE_URL.PAP_AUDIT}`}
          permission={permissions.PAP}
          component={PapAuditList}
        />
        <ProtectedRoute
          path={`/${PAGE_URL.PAP_AUDIT_DETAIL}`}
          permission={permissions.PAP}
          component={PapAuditDetail}
        />

        {/* 미디어 모니터링 */}
        <ProtectedRoute
          path={`/${PAGE_URL.MEDIA_MONITOR_CONFIG}`}
          permission={permissions.MEDIA_MONITOR}
          component={MediaMonitorConfig}
        />
        <ProtectedRoute
          path={`/${PAGE_URL.MEDIA_MONITOR_CONFIG_SETTING}`}
          permission={permissions.MEDIA_MONITOR}
          component={mediaMonitorConfigSetting}
        />
        <ProtectedRoute
          path={`/${PAGE_URL.MEDIA_MONITOR_CONFIG_WINDOW}`}
          permission={permissions.MEDIA_MONITOR}
          component={MediaMonitorConfigWindow}
        />
        <ProtectedRoute
          path={`/${PAGE_URL.MEDIA_MONITOR_PREVIEWS_WINDOW}`}
          permission={permissions.MEDIA_MONITOR}
          component={MediaMonitorPreviewsWindow}
        />
        <ProtectedRoute
          path={`/${PAGE_URL.MEDIA_MONITOR_NEWS}`}
          permission={permissions.MEDIA_MONITOR}
          component={MediaMonitorNews}
        />
        <ProtectedRoute
          path={`/${PAGE_URL.MEDIA_MONITOR_NEWS_WINDOW}`}
          permission={permissions.MEDIA_MONITOR}
          component={MediaMonitorNewsWindow}
        />
        <ProtectedRoute
          path={`/${PAGE_URL.MEDIA_MONITOR_LETTER_WINDOW}`}
          permission={permissions.MEDIA_MONITOR}
          component={MediaMonitorLetterWindow}
        />

        {/* 404 */}
        <ProtectedRoute
          path="*"
          permission={permissions.ADMIN_LOGIN}
          component={PageDoesntExist}
        />
      </Switch>
    </BrowserRouter>
  );
};

export default App;
