import React, { useEffect, useState, useRef, useReducer } from 'react';
import {
  Form,
  Input,
  Button,
  Alert,
  Row,
  Col,
  Upload,
  Select,
  Checkbox,
  notification,
} from 'antd';

import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import '../../../public/css/window.css';
import { parse } from 'qs';
import { useLocation } from 'react-router';
import { useSelector, useDispatch, shallowEqual } from 'react-redux';
import { healthProfileCreators } from '../../../store/reducers/healthProfile.reducer';
import WindowHeader from '../../components/windowHeader';
import ElementLoading from '../../components/elementLoading';

import {
  fetchHealthProfileOption,
  postHealthProfileOption,
  patchHealthProfileOption,
  deleteHealthProfileOption,
} from '../../../services/healthProfileService';
import { useFetch, useMutate } from '../../../hooks/useRequest';
import { ALERT_MESSAGES } from '../../../assets/alert';
import { useAntFileUpload } from '../../util/useAntFileImage';

const HealthProfileOptionWindow = () => {
  const { search } = useLocation();
  const dispatch = useDispatch();
  const { healthProfileQuestionInfo } = useSelector((state) => {
    return {
      healthProfileQuestionInfo:
        state?.healthProfileReducer?.healthProfileQuestion?.data,
    };
  }, shallowEqual);

  const initialState = {
    nextQuestionIdOption: [],
    nextQuestionId: null,
    label: '',
    info: '',
    unit: '',
    order: null,
    placeholder: [],
    isFinish: false,
    isChangeableFinish: false,
    checkNokAuth: false,
    isAllowDecimal: false,
    photo: null,
    activePhoto: null,
    healthProfileQuestionId: null,
  };

  const stateReducer = (prevState, updatedProperty) => ({
    ...prevState,
    ...updatedProperty,
  });

  const [state, setState] = useReducer(stateReducer, initialState);
  const [windowId, setWindowId] = useState('');
  const [isPost, setIsPost] = useState(false);
  const [isPatch, setIsPatch] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [showAlert, setShowAlert] = useState(false);
  const [healthProfileId, setHealthProfileId] = useState(null);
  const [healthProfileQuestionId, setHealthProfileQuestionId] = useState(null);
  const [healthProfileOptionId, setHealthProfileOptionId] = useState(null);
  const [loading, setLoading] = useState(false);
  const [distinctFileInputEvent, setDistinctFileInputEvent] = useState(null);
  const fileUploadSetting = useRef(false);
  const didCancel = useRef(false);
  const uploadCancel = useRef(false);
  const [imgType, setImgType] = useState(false);
  const nextID = useRef(1);
  const [inputItems, setInputItems] = useState([{ id: 0, title: '' }]);

  const {
    publicUrl: distinctPublicUrl,
    done: distinctImageUploadDone,
    imageUpload: distinctImageUpload,
    initialize: distinctUploadInitialize,
  } = useAntFileUpload(distinctFileInputEvent);

  const postPatchData = {
    label: state.label,
    info: state.info,
    unit: state.unit,
    placeholder: inputItems?.map((item) => item.title).filter(Boolean),
    isFinish: state.isFinish,
    isChangeableFinish: state.isChangeableFinish,
    checkNokAuth: state.checkNokAuth,
    isAllowDecimal: state.isAllowDecimal,
    photo: state.photo,
    activePhoto: state.activePhoto,
    order: state.order,
    nextQuestionId: !state.isFinish ? state.nextQuestionId : null,
  };

  const {
    data: fetchHealthProfileOptionData,
    call: callHealthProfileOption,
  } = useFetch(
    null,
    fetchHealthProfileOption,
    healthProfileId,
    healthProfileQuestionId,
    healthProfileOptionId,
  );

  const { mutate: putHealthProfileOption, done: isPosted } = useMutate(
    postHealthProfileOption,
    healthProfileQuestionId,
    postPatchData,
  );

  const { mutate: changeHealthProfileOption, done: isPatched } = useMutate(
    patchHealthProfileOption,
    healthProfileOptionId,
    postPatchData,
  );

  const { mutate: eraseHealthProfileOption, done: isDeleted } = useMutate(
    deleteHealthProfileOption,
    healthProfileOptionId,
  );

  const setupBeforeUnloadListener = (data) => {
    window.addEventListener('beforeunload', (event) => {
      event.preventDefault();
      return window?.opener?.postMessage(data, '/');
    });
  };

  // dispatch actions to fetch data
  useEffect(() => {
    const params = parse(search, {
      ignoreQueryPrefix: true,
    });
    setWindowId(params.id ? params.id : params.new);
    dispatch(healthProfileCreators.fetchAllHealthProfiles.request());
    dispatch(
      healthProfileCreators.fetchAllHealthProfileQuestions.request(
        params.subId,
        params.extraId,
      ),
    );
    if (params.id) {
      setHealthProfileOptionId(params.id);
    } else {
      setHealthProfileOptionId('');
    }
    if (params.subId) {
      setHealthProfileId(params.subId);
    } else {
      setHealthProfileId('');
    }
    if (params.extraId) {
      setHealthProfileQuestionId(params.extraId);
    } else {
      setHealthProfileQuestionId('');
    }
    setupBeforeUnloadListener(`close ${windowId}`);
  }, [search, windowId, dispatch]);

  useEffect(() => {
    if (healthProfileId && healthProfileQuestionId && healthProfileOptionId) {
      callHealthProfileOption();
    }
  }, [
    healthProfileId,
    healthProfileQuestionId,
    healthProfileOptionId,
    callHealthProfileOption,
  ]);

  useEffect(() => {
    if (healthProfileQuestionInfo) {
      setState({ nextQuestionIdOption: healthProfileQuestionInfo });
    }
  }, [healthProfileQuestionInfo, healthProfileQuestionId]);

  // initial state setting after fetching data (title, content, ...)
  useEffect(() => {
    if (
      fetchHealthProfileOptionData &&
      !didCancel.current &&
      healthProfileQuestionInfo
    ) {
      setState({
        nextQuestionId: fetchHealthProfileOptionData.nextQuestionId,
        label: fetchHealthProfileOptionData.label,
        info: fetchHealthProfileOptionData.info,
        unit: fetchHealthProfileOptionData.unit,
        order: fetchHealthProfileOptionData.order,
        placeholder: fetchHealthProfileOptionData.placeholder,
        isFinish: fetchHealthProfileOptionData.isFinish,
        isChangeableFinish: fetchHealthProfileOptionData.isChangeableFinish,
        checkNokAuth: fetchHealthProfileOptionData.checkNokAuth,
        isAllowDecimal: fetchHealthProfileOptionData.isAllowDecimal,
        photo: fetchHealthProfileOptionData.photo,
        activePhoto: fetchHealthProfileOptionData.activePhoto,
      });
      setInputItems(
        fetchHealthProfileOptionData.placeholder?.map((history, index) => ({
          id: index,
          title: history,
        })),
      );
      didCancel.current = true;
      setIsLoading(false);
    } else if (healthProfileOptionId === '' && !didCancel.current) {
      didCancel.current = true;
      setIsLoading(false);
    }
  }, [
    fetchHealthProfileOptionData,
    healthProfileQuestionInfo,
    healthProfileOptionId,
    healthProfileQuestionId,
  ]);

  useEffect(() => {
    let message = '';
    if (isPosted) {
      message = ALERT_MESSAGES.CREATE;
    }
    if (isPatched) {
      message = ALERT_MESSAGES.UPDATE;
    }
    if (isDeleted) {
      message = ALERT_MESSAGES.DELETE;
    }
    if (isPosted || isPatched || isDeleted) {
      alert(message);
      window.close();
    }
  }, [isDeleted, isPosted, isPatched]);

  const onFinish = (values) => {
    setShowAlert(false);
    setState({
      label: values.label,
      info: values.info,
      unit: values.unit,
    });
    if (healthProfileOptionId) {
      if (window.confirm('수정하시겠습니까?')) {
        setIsPatch(true);
      }
    } else if (window.confirm('생성하시겠습니까?')) {
      setIsPost(true);
    }
  };

  const onFinishFailed = () => {
    setShowAlert(true);
  };

  const onReset = () => {
    if (window.confirm('취소하시겠습니까?')) {
      window.close();
    }
  };

  const onDelete = () => {
    if (window.confirm('삭제하시겠습니까?')) {
      eraseHealthProfileOption();
    }
  };

  useEffect(() => {
    if (isPatch) {
      changeHealthProfileOption();
      setIsPatch(false);
    }
    if (isPost) {
      putHealthProfileOption();
      setIsPost(false);
    }
  }, [isPatch, isPost]);

  const beforeUpload = (file) => {
    const isSupportedImageType =
      file.type === 'image/jpeg' ||
      file.type === 'image/png' ||
      file.type === 'image/svg+xml';
    if (!isSupportedImageType) {
      notification.error({
        message: 'JPG/PNG/SVG만 업로드 가능합니다.',
        description: '이미지 업로드를 다시 시도해주세요.',
      });
    }
    const isLt10M = file.size / 1024 / 1024 < 10;
    if (!isLt10M) {
      notification.error({
        message: '이미지가 10MB보다 큽니다.',
        description: '이미지 업로드를 다시 시도해주세요.',
      });
    }
    return isSupportedImageType && isLt10M;
  };

  const uploadImg = (e) => {
    if (e !== undefined) {
      setImgType('photo');
      setDistinctFileInputEvent(e);
      uploadCancel.current = true;
      setLoading(true);
    }
  };

  const uploadActiveImg = (e) => {
    if (e !== undefined) {
      setImgType('activePhoto');
      setDistinctFileInputEvent(e);
      uploadCancel.current = true;
      setLoading(true);
    }
  };

  const noop = () => {};

  const deleteThumbImg = (type) => {
    setState({
      [type]: '',
    });
    notification.success({
      message: '이미지를 삭제하였습니다.',
      description: '이미지를 다시 업로드해주세요.',
    });
  };

  const uploadProps = {
    customRequest: noop,
    onChange: uploadImg,
    onPreview: noop,
  };

  const uploadActiveProps = {
    customRequest: noop,
    onChange: uploadActiveImg,
    onPreview: noop,
  };

  useEffect(() => {
    if (distinctFileInputEvent && uploadCancel.current) {
      distinctImageUpload();
      uploadCancel.current = false;
      fileUploadSetting.current = true;
    }
  }, [distinctFileInputEvent, distinctImageUpload]);

  useEffect(() => {
    if (distinctImageUploadDone && distinctPublicUrl) {
      notification.success({
        message: '이미지 업로드에 성공하였습니다.',
        description: '설문 이미지 등록에 성공하였습니다.',
      });
      setState({ [[imgType]]: distinctPublicUrl });
      distinctUploadInitialize();
      setLoading(false);
    }
  }, [distinctImageUploadDone, distinctPublicUrl, distinctUploadInitialize]);

  const uploadButton = (
    <div>
      {loading ? <LoadingOutlined /> : <PlusOutlined />}
      <div
        style={{
          marginTop: 8,
        }}
      >
        Upload
      </div>
    </div>
  );

  const addInput = () => {
    const input = {
      id: nextID.current,
      title: '',
    };
    setInputItems([...inputItems, input]);
    nextID.current += 1;
  };

  const deleteInput = (index) => {
    setInputItems(inputItems.filter((item) => item.id !== index));
  };

  const handleTitleChange = (e, index) => {
    const inputItemsCopy = JSON.parse(JSON.stringify(inputItems));
    inputItemsCopy[index].title = e.target.value;
    setInputItems(inputItemsCopy);
  };

  if (isLoading) return <ElementLoading type="설문 선택지" />;
  return (
    <>
      <WindowHeader title="설문 선택지" />
      <Row span={24} style={{ padding: 16 }}>
        <Col span={24}>
          <Form
            name="basic"
            labelCol={{ span: 4 }}
            wrapperCol={{ span: 20 }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            initialValues={{
              remember: true,
              order: state.order,
              label: state.label,
              info: state.info,
              unit: state.unit,
              placeholder: state.placeholder,
              isFinish: state.isFinish,
              isChangeableFinish: state.isChangeableFinish,
              checkNokAuth: state.checkNokAuth,
              isAllowDecimal: state.isAllowDecimal,
              photo: state.photo,
              activePhoto: state.activePhoto,
              nextQuestionId: state.nextQuestionId,
            }}
          >
            <Form.Item label="종속 항목">
              <Input
                prefix={
                  healthProfileQuestionInfo?.filter(
                    (healthProfileQuestion) =>
                      healthProfileQuestion.id === healthProfileQuestionId,
                  )[0].mainQuestion
                }
                disabled
              />
            </Form.Item>
            <Form.Item label="순서" name="order">
              <Input
                placeholder="순서를 생성 후 메인 페이지에서 지정해주세요!"
                disabled
              />
            </Form.Item>
            <Form.Item
              label="선택 답변"
              name="label"
              rules={[
                {
                  required: true,
                  message: '선택 답변을 확인해주세요.',
                },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item label="부가 설명" name="info">
              <Input />
            </Form.Item>
            <Form.Item label="멀티텍스트 문구" name="placeholder">
              <Button
                style={{ width: 104, marginBottom: 16 }}
                onClick={addInput}
                disabled={inputItems?.length > 0}
              >
                추가하기
              </Button>
              {inputItems?.map((item, index) => (
                <div>
                  <Form.Item name="specialty">
                    <label>{index + 1}번 문구:&nbsp;</label>
                    <Input
                      type="text"
                      className={`title-${index}`}
                      onChange={(e) => handleTitleChange(e, index)}
                      value={item.title}
                      style={{ width: '80%' }}
                    />
                    <Button danger onClick={() => deleteInput(item.id)}>
                      삭제
                    </Button>
                  </Form.Item>
                </div>
              ))}
            </Form.Item>
            <Form.Item label="카테 고리 여부" name="isFinish">
              <Checkbox
                onChange={(e) =>
                  setState({
                    isFinish: e.target.checked,
                  })
                }
                checked={state?.isFinish}
              >
                선택되면 본 선택지의 종속 질문은 카테고리의 마지막 질문입니다.
              </Checkbox>
            </Form.Item>
            {healthProfileQuestionInfo?.filter(
              (healthProfileQuestion) =>
                healthProfileQuestion.id === healthProfileQuestionId,
            )[0].mainQuestionType === 'number' && (
              <Form.Item
                label="단위 문구"
                name="unit"
                rules={[
                  {
                    required: true,
                    message: '단위 문구를 확인해주세요.',
                  },
                ]}
              >
                <Input />
              </Form.Item>
            )}
            {!state.isFinish && (
              <Form.Item name="nextQuestionId" label="다음 질문">
                <Select
                  allowClear
                  style={{ width: '100%' }}
                  placeholder="다음 질문을 선택해주세요."
                  name="nextQuestionId"
                  filterOption={(input, option) =>
                    option.props.value
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0 ||
                    option.props.key
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                  onChange={(e) => setState({ nextQuestionId: e })}
                >
                  {state.nextQuestionIdOption?.map((status) => (
                    <Select.Option
                      key={status?.id}
                      id={status?.id}
                      value={status?.id}
                    >
                      {status?.mainQuestion}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            )}
            <Form.Item label="동적 질문 여부" name="isChangeableFinish">
              <Checkbox
                onChange={(e) =>
                  setState({
                    isChangeableFinish: e.target.checked,
                  })
                }
                checked={state?.isChangeableFinish}
              >
                선택되면 본 선택지의 종속 질문의 동적설문의 마지막 질문입니다.
              </Checkbox>
            </Form.Item>
            <Form.Item label="만 14세 미만" name="checkNokAuth">
              <Checkbox
                onChange={(e) =>
                  setState({
                    checkNokAuth: e.target.checked,
                  })
                }
                checked={state?.checkNokAuth}
              >
                선택되면 본 선택지는 만 14세 미만 보호자 동의 필요합니다.
              </Checkbox>
            </Form.Item>
            {healthProfileQuestionInfo?.filter(
              (healthProfileQuestion) =>
                healthProfileQuestion.id === healthProfileQuestionId,
            )[0].mainQuestionType === 'number' && (
              <Form.Item label="소수점 입력 허용" name="isAllowDecimal">
                <Checkbox
                  onChange={(e) =>
                    setState({
                      isAllowDecimal: e.target.checked,
                    })
                  }
                  checked={state?.isAllowDecimal}
                >
                  선택되면 유저가 소수점을 입력할 수 있습니다.
                </Checkbox>
              </Form.Item>
            )}
            <div className="fade_in_box">
              앱 버전 4.1.0 부터 선택 이미지 노출 되지 않습니다. (&apos;선택
              답변&apos;만 노출)
            </div>
            <Form.Item label="선택 전 그림" name="photo">
              <Upload
                {...uploadProps}
                listType="picture-card"
                showUploadList={false}
                beforeUpload={beforeUpload}
              >
                {state?.photo ? (
                  <img
                    src={state?.photo}
                    alt="avatar"
                    style={{
                      width: '100%',
                    }}
                  />
                ) : (
                  uploadButton
                )}
              </Upload>
              {state?.photo && (
                <Button
                  type="primary"
                  style={{ width: 104 }}
                  danger
                  onClick={() => deleteThumbImg('photo')}
                >
                  설문 그림 삭제
                </Button>
              )}
            </Form.Item>
            <Form.Item label="선택 후 그림" name="activePhoto">
              <Upload
                {...uploadActiveProps}
                listType="picture-card"
                showUploadList={false}
                beforeUpload={beforeUpload}
              >
                {state?.activePhoto ? (
                  <img
                    src={state?.activePhoto}
                    alt="avatar"
                    style={{
                      width: '100%',
                    }}
                  />
                ) : (
                  uploadButton
                )}
              </Upload>
              {state?.activePhoto && (
                <Button
                  type="primary"
                  style={{ width: 104 }}
                  danger
                  onClick={() => deleteThumbImg('activePhoto')}
                >
                  설문 그림 삭제
                </Button>
              )}
            </Form.Item>
            <Form.Item
              wrapperCol={{
                offset: 4,
                span: 20,
              }}
            >
              <Button type="primary" htmlType="submit" style={{ width: 100 }}>
                저장
              </Button>
              <Button
                htmlType="button"
                style={{ width: 100 }}
                onClick={onReset}
              >
                취소
              </Button>
              {healthProfileQuestionId && (
                <Button
                  danger
                  htmlType="button"
                  style={{ width: 100, marginLeft: 8 }}
                  onClick={onDelete}
                >
                  삭제
                </Button>
              )}
            </Form.Item>
            {showAlert && (
              <Col span={20} offset={4}>
                <Alert
                  message="에러"
                  description="필수값을 확인해주세요."
                  type="error"
                  showIcon
                />
              </Col>
            )}
          </Form>
        </Col>
      </Row>
    </>
  );
};
export default HealthProfileOptionWindow;
