import { EyeTwoTone } from '@ant-design/icons';
import { Button, Col, Divider, Radio, Row, Switch } from 'antd';
import dayjs from 'dayjs';
import React, { useCallback, useState } from 'react';
import useWindow from '../../../hooks/useWindow';
import AntList from '../../components/antList';

export const MediaMonitorPreviews = ({
  previews,
  config,
  diseaseTypeData,
  previewLoading,
  previewLoadingText,
  selectedPreview,
  setSelectedPreview,
}) => {
  const [pageUpdate, setPageUpdate] = useState(1);
  const { createWindow } = useWindow();

  const columns = [
    {
      title: '선택',
      render: (_, record) => (
        <Radio
          id={record.id}
          onClick={() => setSelectedPreview(record.id)}
          checked={selectedPreview === record.id}
        />
      ),
    },
    {
      title: '국내/해외',
      dataIndex: 'lang',
      key: 'lang',
      filters: [
        { text: '국내', value: 'ko' },
        { text: '해외', value: 'en' },
      ],
      onFilter: (value, record) => record.lang === value,
      render: (text) => (text === 'ko' ? '국내' : '해외'),
    },
    {
      title: '기사 발행일',
      dataIndex: 'publishedDate',
      key: 'publishedDate',
      sorter: (a, b) =>
        dayjs(a.publishedDate)?.compareTo(dayjs(b.publishedDate)),
      render: (text) => (text ? dayjs(text).format('YY.MM.DD') : '-'),
    },
    {
      title: '기사 제목',
      dataIndex: 'title',
      key: 'title',
      sorter: (a, b) => a.title.localeCompare(b.title),
      render: (text, record) => (
        <a href={record.url} target="_blank" rel="noreferrer">
          {text}
        </a>
      ),
    },
    {
      title: '대상 질환',
      dataIndex: 'diseaseTypeId',
      key: 'diseaseTypeId',
      filters: config?.diseaseTypeIds?.map((id) => ({
        text: diseaseTypeData?.find((data) => data.id === id)?.krName ?? id,
        value: id,
      })),
      onFilter: (value, record) => record.diseaseTypeId === value,
      render: (value) =>
        diseaseTypeData?.find((data) => data.id === value)?.krName ?? value,
    },
    {
      title: '적합성',
      dataIndex: 'eligible',
      key: 'eligible',
      filters: [
        { text: '적합', value: true },
        { text: '부적합', value: false },
      ],
      onFilter: (value, record) => record.eligible === value,
      render: (value) => <Switch disabled checked={value} />,
    },
  ];

  const handlePreviewsWindow = useCallback(() => {
    createWindow({ id: config.id, dataType: 'mediaMonitorPreviews' });
  }, [config]);

  return (
    <Row span={24}>
      <Col span={24}>
        <h5>
          <EyeTwoTone className="userTitleIcon" />
          적재 미리보기
        </h5>
        <Button
          type="link"
          style={{ float: 'right' }}
          onClick={handlePreviewsWindow}
        >
          새 창으로 열기
        </Button>
        <Divider plain />
        <AntList
          columns={columns}
          dataSource={previews}
          pagination={{
            current: pageUpdate,
            onChange: (page) => setPageUpdate(page),
            pageSize: 20,
            showSizeChanger: true,
          }}
          loading={
            previewLoading
              ? { spinning: previewLoading, tip: previewLoadingText }
              : undefined
          }
        />
      </Col>
    </Row>
  );
};
